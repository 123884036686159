<kendo-popup *ngIf="isVisible" [offset]="position">
  <div class="k-menu-popup">
    <ul class="k-group k-menu-group k-reset k-menu-group-md">
      <li
        *ngFor="let status of taskStatusTypeList"
        (click)="selectStatus(status)"
        [ngClass]="{ selected: taskData?.taskStatus === status.key }"
      >
        <span class="k-link k-menu-link">{{ status.value }}</span>
      </li>
    </ul>
  </div>
</kendo-popup>
