<kendo-popup *ngIf="isVisible" [popupSettings]="popupSettings" [offset]="position">
  <div class="priority-dropdown">
    <kendo-button
      *ngFor="let priority of priorityList"
      class="priority-option {{ priority.text.toLowerCase() }}"
      (click)="setPriority(priority.id)"
      [themeColor]="getThemeColor(priority.text)"
      [fillMode]="'solid'"
    >
      {{ priority.text }}
    </kendo-button>
    <!-- <kendo-button
      class="priority-option none"
      (click)="setPriority('None')"
      [themeColor]="'default'"
      [fillMode]="'solid'"
    >
      None
    </kendo-button>
    <kendo-button
      class="priority-option low"
      (click)="setPriority('Low')"
      [themeColor]="'success'"
      [fillMode]="'solid'"
    >
      Low
    </kendo-button>
    <kendo-button
      class="priority-option medium"
      (click)="setPriority('Medium')"
      [themeColor]="'warning'"
      [fillMode]="'solid'"
    >
      Medium
    </kendo-button>
    <kendo-button
      class="priority-option high"
      (click)="setPriority('High')"
      [themeColor]="'error'"
      [fillMode]="'solid'"
    >
      High
    </kendo-button> -->
  </div>
</kendo-popup>
