import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TaskType } from 'src/app/enum/task-type';
import { CommonService } from 'src/core/services';
import { Priority } from 'tasks/task/config/src/enums/priority';
import { TaskService } from 'projects/task-service/src/lib/proxy/task-service/task/task.service';
import {
  CreateUpdateTaskDto,
  TaskStatusType,
} from 'projects/task-service/src/lib/proxy/task-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { GetIdentityUsersInput, IdentityUserService } from '@abp/ng.identity/proxy';
import { ListService, SessionStateService } from '@abp/ng.core';
import { NotificationMessage, NotificationTextMessage } from 'src/app/enum/notification';
import { ToasterService } from '@abp/ng.theme.shared';
import {
  ProjectBoardColumnService,
  // ProjectService,
} from 'projects/project-service/src/lib/proxy/project-service';
import { ProjectUserService } from '@proxy/project-service/project';
import { FileVersionDto } from 'projects/file-service/src/lib/proxy/file-service/dtos';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { DialogService } from '@progress/kendo-angular-dialog';
import { AttachTaskFilePopupComponent } from '../../attach-task-file-popup/attach-task-file-popup.component';
import { Observable } from 'rxjs';
import { SetTaskStatus } from 'src/app/core/store/task.action';
import { Store } from '@ngxs/store';
import { TaskState } from 'src/app/core/store/task.state';
import { DrawerTab } from 'src/app/enum/drawer-tab-enum';
import { SubTasksComponent } from '../../shared/sub-tasks/sub-tasks.component';
import { orderBy } from '@progress/kendo-data-query';

interface TaskStatusTypeOption {
  key: TaskStatusType;
  value: string;
}

export class TaskDateDto {
  year: number;
  month: number;
  day: number;

  constructor(year: number, month: number, day: number) {
    this.year = year;
    this.month = month;
    this.day = day;
  }
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-add-task-more-details',
  templateUrl: './add-task-more-details.component.html',
  styleUrls: ['./add-task-more-details.component.scss'],
})
export class AddTaskMoreDetailsComponent implements OnInit {
  taskTypeList: { id: TaskType; name: string; iconClass: string }[] = [];
  priorityList: { id: Priority; text: string }[] = [];

  customStyleContent = `
    .k-content * {
      font-family: 'Poppins', sans-serif;
      color: #404a5f;
    }

    .k-content .k-placeholder {
      font-size: 13px;
      color: #909EB2;
    }`;

  popupClass = 'task-type-dropdown';

  @Input() taskId = 0;
  @Input() taskDetails: any;
  @Input() assigneListItems = [];
  @Input() projectId = 0;
  @Input() sortingName = '';
  @Input() loadStateData: any;
  @Input() drawerSelectedTabId: number = DrawerTab.File;
  @Input() isFromEditAction: boolean = false;

  @Output()
  readonly reloadTaskList = new EventEmitter<any>();

  subtaskList: any[] = [];
  assigneeList: any[] = [];
  userList: any[];
  fileList: any[] = [];

  timerInterval: any;
  startDate: any;
  dueDate: any;
  projectTaskStatusTypeList: any;

  taskTypeIconClass: string = '';
  taskListId = 0;
  editedRowIndex: number;
  commentCount: number = 0;

  taskStatusType = TaskStatusType;
  priorityEnum = Priority;
  drawerTab = DrawerTab;

  isRender = false;
  isShowAssigneeMultiSelect = false;
  isShowDueDateInput = false;
  isShowStartDateInput = false;

  isShowPriorityInput = false;
  isAllSubTaskCompleted = false;
  isDrawerOpened = true;
  isProjectBillable = false;

  formGroup: FormGroup;
  taskForm: FormGroup;

  taskStatus$: Observable<any>;

  @ViewChild(SubTasksComponent) subTasksComponent!: SubTasksComponent;

  constructor(
    public commonService: CommonService,
    public taskService: TaskService,
    private spinnerService: NgxSpinnerService,
    private dialogService: DialogService,
    protected service: IdentityUserService,
    public readonly list: ListService<GetIdentityUsersInput>,
    private toasterService: ToasterService,
    //  private projectService: ProjectService,
    private http: HttpClient,
    private sessionState: SessionStateService,
    private projectUser: ProjectUserService,
    private store: Store,
    private projectBoardColumnService: ProjectBoardColumnService,
  ) {
    this.taskTypeList = this.commonService.getTaskTypeList();
    this.priorityList = this.commonService.getPriorityList();
  }

  ngOnInit(): void {
    this.setForm();
    // this.getProjectDetail(); //This is user in app-time-log-grid
    this.getAssigneeList();
    this.getDataById();
    this.getProjectTaskStatusList();
    this.taskStatus$ = this.store.select(TaskState.getTaskStatus);
  }

  getCommentCount(count: number): void {
    this.commentCount = count;
  }

  onReloadPage(data: any): void {
    this.taskId = data.taskId;
    this.getDataById();
  }

  getAssigneeList(): void {
    const params = {
      sorting: 'name asc',
      skipCount: 0,
      maxResultCount: 1000,
    };

    this.projectUser.getPeople(this.projectId, params).subscribe(res => {
      if (res.items.length > 0) {
        this.assigneeList = this.commonService.preprocessAssigneeList(res.items);
        this.assigneListItems = this.assigneeList.map(assignee => ({
          userName: assignee.userName,
          userId: assignee.userId,
        }));
      }
    });
  }

  openAttachedFile(): void {
    const dialogRef = this.dialogService.open({
      content: AttachTaskFilePopupComponent,
      width: 500,
      cssClass: 'add-time-log-quickly',
    });

    dialogRef.content.instance.taskId = this.taskId;
    dialogRef.content.instance.taskName = this.taskForm.controls.taskName.value;
    dialogRef.result.subscribe(res => {
      if (res) {
      }
    });
  }

  downloadFile(file: FileVersionDto): void {
    let apiURL = environment.apis.default.url;
    const tenant = this.sessionState.getTenant();

    if (tenant?.id) {
      apiURL = apiURL.replace('{0}', tenant?.name);
    } else {
      apiURL = apiURL.replace('{0}.', '');
    }

    this.http
      .get(`${apiURL}api/file/${file.fileId}/download`, {
        responseType: 'blob',
      })
      .subscribe({
        next: (response: Blob) => {
          const url = window.URL.createObjectURL(response);

          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = file.fileName;
          anchor.click();

          window.URL.revokeObjectURL(url);
        },
        error: error => {
          console.error('Error downloading the file', error);
        },
        complete: () => {
          this.toasterService.success(
            NotificationMessage.downloadFileSuccessMsg,
            '',
            this.commonService.toasterMessageConfiguration,
          );
        },
      });
  }

  getFileIconClass(fileName: string): string {
    const extension = fileName.split('.').pop()?.toLowerCase();
    switch (extension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return 'fas fa-image';
      case 'pdf':
        return 'fas fa-file-pdf';
      case 'xls':
      case 'xlsx':
        return 'fas fa-file-excel';
      default:
        return 'fas fa-file';
    }
  }

  // getProjectDetail(): void {
  //   this.projectService.get(this.projectId).subscribe((res: any) => {
  //     this.isProjectBillable = res.isBillable;
  //   });
  // }

  setTaskTypeValue(event): any {
    this.taskForm.controls.taskType.setValue(event.id);
  }

  getDataById(): void {
    this.spinnerService.show();
    this.taskService.get(this.taskId).subscribe(res => {
      this.taskListId = res.taskListId;
      this.spinnerService.hide();

      const { hours, minutes } = this.commonService.convertMinutesToHoursAndMinutes(
        res.estimateMinutes,
      );
      this.store.dispatch(new SetTaskStatus(res.taskStatus));

      this.taskForm.patchValue({
        taskStatus: res.taskStatus,
        taskName: res.name,
        taskType: res.taskType,
        estimateMinutes: res.estimateMinutes,
        description: res.description,
        assignee: res.users,
        startDate: res.startdate ? new Date(res.startdate) : '',
        dueDate: res.duedate ? new Date(res.duedate) : '',
        priority: res.priority,
        hasChildren: !!res.subTaskIds,
        isAllSubTaskCompleted: res.isAllSubTaskCompleted,
        taskListId: res.taskListId,
        parentTaskId: res.parentTaskId,
        estimateHour: hours,
        estimateMinute: minutes,
        projectTaskStatus: res.columnId,
      });

      this.isRender = true;
    });
  }

  setForm(): void {
    this.taskForm = new FormGroup({
      taskListId: new FormControl(''),
      parentTaskId: new FormControl(null),
      taskStatus: new FormControl(''),
      projectTaskStatus: new FormControl(''),
      taskName: new FormControl('', [Validators.required]),
      taskType: new FormControl(this.taskTypeList[0].id),
      description: new FormControl(''),
      assignee: new FormControl(null),
      startDate: new FormControl(''),
      dueDate: new FormControl(''),
      priority: new FormControl(''),
      estimateMinutes: new FormControl(''),
      hasChildren: new FormControl(false),
      isAllSubTaskCompleted: new FormControl(false),
      estimateHour: new FormControl(''),
      estimateMinute: new FormControl(''),
    });

    if (this.taskDetails != null || this.taskDetails != undefined) {
      this.taskListId = this.taskDetails.taskListId;
      const { hours, minutes } = this.commonService.convertMinutesToHoursAndMinutes(
        this.taskDetails.estimateMinutes,
      );
      this.store.dispatch(new SetTaskStatus(this.taskDetails.taskStatus));
      this.taskForm.patchValue({
        taskStatus: this.taskDetails.taskStatus,
        taskName: this.taskDetails.taskName,
        taskType: this.taskDetails.taskType,
        estimateMinutes: this.taskDetails.estimateMinutes,
        description: this.taskDetails.description,
        assignee: this.taskDetails.assignee,
        startDate: this.taskDetails.startDate ? new Date(this.taskDetails.startDate) : '',
        dueDate: this.taskDetails.dueDate ? new Date(this.taskDetails.dueDate) : '',
        priority: this.taskDetails.priority,
        hasChildren: this.taskDetails.hasChildren,
        isAllSubTaskCompleted: this.taskDetails.isAllSubTaskCompleted,
        taskListId: this.taskDetails.taskListId,
        parentTaskId: this.taskDetails.parentTaskId,
        estimateHour: hours,
        estimateMinute: minutes,
        projectTaskStatus: this.taskDetails.columnId,
      });

      this.isRender = true;
    } else {
      this.getDataById();
    }
  }

  getIconClass(taskType: number): string {
    return this.taskTypeList.find(x => x.id === taskType).iconClass;
  }

  onTaskCompleteChange(): void {
    const checkbox = event.target as HTMLInputElement;
    const isChecked = checkbox.checked;

    if (this.subTasksComponent.allSubTaskCompleted()) {
      if (isChecked) {
        this.taskService.markTaskAsCompleteById(this.taskId).subscribe(() => {
          this.store.dispatch(new SetTaskStatus(TaskStatusType.Completed));
          this.taskForm.controls['taskStatus'].setValue(TaskStatusType.Completed);
          this.toasterService.success(
            NotificationTextMessage.taskUpdatedMessage,
            '',
            this.commonService.toasterMessageConfiguration,
          );
        });
      } else {
        this.taskService.markTaskAsReopenedById(this.taskId).subscribe(() => {
          this.store.dispatch(new SetTaskStatus(TaskStatusType.ReOpened));
          this.taskForm.controls['taskStatus'].setValue(TaskStatusType.ReOpened);
          this.toasterService.success(
            NotificationTextMessage.taskUpdatedMessage,
            '',
            this.commonService.toasterMessageConfiguration,
          );
        });
      }
    } else {
      this.toasterService.info(
        NotificationTextMessage.taskCompletedErrorMessage,
        '',
        this.commonService.toasterMessageConfiguration,
      );
      checkbox.checked = false;
    }
  }

  onSave(): void {
    const dueDate = new Date(this.taskForm.controls.dueDate.value);
    const startDate = new Date(this.taskForm.controls.startDate.value);

    const estimateTime = `${
      this.taskForm.value.estimateHour === '' ? 0 : this.taskForm.value.estimateHour
    }:${this.taskForm.value.estimateMinute === '' ? 0 : this.taskForm.value.estimateMinute}`;

    const estimateMinutes = this.commonService.getTotalMinutesFromHoursAndMinutes(estimateTime);

    const param: any = {
      isQuickEdit: true,
      id: this.taskId,
      name: this.taskForm.controls.taskName.value,
      description: this.taskForm.controls.description.value,
      progress: 20,
      startdate:
        this.taskForm.controls.startDate.value === ''
          ? null
          : new TaskDateDto(startDate.getFullYear(), startDate.getMonth() + 1, startDate.getDate()),
      duedate:
        this.taskForm.controls.dueDate.value === ''
          ? null
          : new TaskDateDto(dueDate.getFullYear(), dueDate.getMonth() + 1, dueDate.getDate()),
      priority: +this.taskForm.controls.priority.value,
      estimateMinutes: estimateMinutes,
      taskListId: this.taskForm.controls.taskListId.value,
      parentTaskId: this.taskForm.controls.parentTaskId.value,
      notify: true,
      assignedToUserIds:
        this.taskForm.controls.assignee.value.length > 0
          ? this.taskForm.controls.assignee.value.map(item => item.userId)
          : [],
      taskType: this.taskForm.controls.taskType.value,
      taskStatus: this.taskForm.controls.taskStatus.value,
      subTasks: [],
      projectId: this.projectId,
      columnId: this.taskForm.controls.projectTaskStatus.value,
    };

    this.taskService.update(this.taskId, param).subscribe(
      res => {
        this.spinnerService.hide();
        this.toasterService.success(
          NotificationMessage.updateTaskSuccessMsg,
          '',
          this.commonService.toasterMessageConfiguration,
        );

        param.assignedToUserIds = this.assigneListItems.filter(x =>
          param.assignedToUserIds.includes(x.userId),
        );
        param.projectColumnName = res.projectColumnName;

        const sendData = {
          isSuccess: true,
          taskStatus: param.taskStatus,
          data: param,
        };

        this.store.dispatch(new SetTaskStatus(param.taskStatus));

        this.reloadTaskList.emit(sendData);
        document.body.style.overflow = 'auto';
      },
      err => {
        this.spinnerService.hide();
      },
    );
  }

  closeDrawer() {
    this.reloadTaskList.emit(false);
    document.body.style.overflow = 'auto';
  }

  onPaste(e) {
    e.preventDefault();
    return false;
  }

  onAssigneeSearch(searchTerm: string): void {
    const contains = (value: string) => (item: { userName: string; userId: number }) =>
      item.userName.toLowerCase().includes(value.toLowerCase());
    this.assigneeList = this.assigneListItems.filter(contains(searchTerm));
  }

  getProjectTaskStatusList(): void {
    const data: any = {
      projectId: this.projectId,
    };

    this.spinnerService.show();

    this.projectBoardColumnService.getList(data).subscribe(
      response => {
        this.spinnerService.hide();
        this.projectTaskStatusTypeList = response.items.map((item: any) => ({
          key: item.id,
          value: item.columnname,
        }));

        this.projectTaskStatusTypeList = orderBy(this.projectTaskStatusTypeList, [
          { field: 'key', dir: 'asc' },
        ]);
      },
      err => {
        this.spinnerService.hide();
      },
    );
  }
}
