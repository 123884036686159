import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { TaskService } from 'projects/task-service/src/lib/proxy/task-service/task';
import {
  AddEvent,
  CancelEvent,
  EditEvent,
  GridComponent,
  RemoveEvent,
  SaveEvent,
} from '@progress/kendo-angular-grid';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Priority } from 'tasks/task/config/src/enums/priority';
import { TaskType } from 'src/app/enum/task-type';
import { NotificationTextMessage } from 'src/app/enum/notification';
import swal from 'sweetalert/dist/sweetalert.min.js';
import { ToasterService } from '@abp/ng.theme.shared';
import { CommonService } from 'src/core/services';
import {
  CreateUpdateTaskDto,
  TaskStatusType,
} from 'projects/task-service/src/lib/proxy/task-service';
import { Select, Store } from '@ngxs/store';
import {
  AddSubTask,
  DeleteSubTask,
  GetSubTaskList,
  MarkTaskAsCompletedSubTask,
  UpdateSubTask,
} from 'src/app/core/store/task.action';
import { TaskState } from 'src/app/core/store/task.state';
import { map, Observable, switchMap } from 'rxjs';

const createSubTaskFormGroup = dataItem =>
  new FormGroup({
    id: new FormControl(dataItem.id),
    name: new FormControl(dataItem.name, Validators.required),
    users: new FormControl(dataItem.users),
    dueDate: new FormControl(dataItem.dueDate),
    priority: new FormControl(dataItem.priority),
    taskId: new FormControl(dataItem.taskId),
    taskType: new FormControl(dataItem.taskType),
    taskStatus: new FormControl(dataItem.taskStatus),
    estimateMinutes: new FormControl(dataItem.estimateMinutes),
    isAllSubTaskCompleted: new FormControl(dataItem.isAllSubTaskCompleted),
  });

export class TaskDateDto {
  year: number;
  month: number;
  day: number;

  constructor(year: number, month: number, day: number) {
    this.year = year;
    this.month = month;
    this.day = day;
  }
}
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-sub-tasks',
  templateUrl: './sub-tasks.component.html',
  styleUrls: ['./sub-tasks.component.scss'],
})
export class SubTasksComponent implements OnInit {
  @Input() taskId: any;
  @Input() taskListId: any;
  @Input() sortingName = '';
  @Input() loadStateData: any;

  @Output()
  readonly reloadPage = new EventEmitter<any>();

  isAllSubTaskCompleted = false;

  editedRowIndex: number;
  formGroup: FormGroup;
  taskStatusType = TaskStatusType;

  subTaskList$: Observable<any[]>;

  taskTypeList: { id: TaskType; name: string; iconClass: string }[] = [];

  constructor(
    private taskService: TaskService,
    private spinnerService: NgxSpinnerService,
    private toasterService: ToasterService,
    public commonService: CommonService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.subTaskList$ = this.store.select(TaskState.getSubTaskList);
    this.getSubtask();

    this.taskTypeList = this.commonService.getTaskTypeList();
  }

  checkIsSubDisable(dataItem: any): boolean {
    if (!!dataItem.subTaskIds) {
      return !dataItem.isAllSubTaskCompleted;
    }
    return false;
  }

  getSubTaskDataById(data: any): void {
    this.taskId = data.id;
    const sendData = {
      taskId: this.taskId,
    };

    this.getSubtask();
    this.reloadPage.emit(sendData);
  }

  onSubTaskCompleteChange(data: any, index: any): void {
    if (data.taskStatus === TaskStatusType.Completed) {
      this.taskService.markTaskAsReopenedById(data.id).subscribe(res => {
        if (res) {
          this.toasterService.success(
            NotificationTextMessage.taskUpdatedMessage,
            '',
            this.commonService.toasterMessageConfiguration,
          );

          this.store.dispatch(new MarkTaskAsCompletedSubTask(data.id, TaskStatusType.ReOpened));
        }
      });
    } else {
      this.taskService.markTaskAsCompleteById(data.id).subscribe(res => {
        if (res) {
          this.toasterService.success(
            NotificationTextMessage.taskUpdatedMessage,
            '',
            this.commonService.toasterMessageConfiguration,
          );
          this.store.dispatch(new MarkTaskAsCompletedSubTask(data.id, TaskStatusType.Completed));
        }
      });
    }

    this.isAllSubTaskCompleted = this.checkIsDisable();
  }

  onRemoveSubTask({ dataItem }: RemoveEvent) {
    swal({
      title: NotificationTextMessage.areYouSureMessage,
      text: NotificationTextMessage.deleteMessageHeader + dataItem.name + ' ?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then(confirmed => {
      if (confirmed) {
        this.taskService.delete(dataItem.id).subscribe(res => {
          this.store.dispatch(new DeleteSubTask(dataItem.id));
          //this.subtaskList = this.subtaskList.filter(subtask => subtask.id !== dataItem.id);
        });
      }
    });
  }

  onAddSubTask({ sender }: AddEvent): void {
    this.closeEditor(sender);

    this.formGroup = createSubTaskFormGroup({
      id: 0,
      name: '',
    });

    sender.addRow(this.formGroup);
  }

  onSaveSubTask({ sender, rowIndex, formGroup, isNew }: SaveEvent): void {
    const newTaskData = formGroup.value;
    this.isAllSubTaskCompleted = this.checkIsDisable();

    const param: CreateUpdateTaskDto = {
      isQuickEdit: true,
      id: isNew ? 0 : newTaskData.id,
      name: newTaskData.name,
      description: '',
      progress: 20,
      taskListId: this.taskListId,
      startdate: null,
      duedate: null,
      priority: Priority.None,
      estimateMinutes: 0,
      parentTaskId: this.taskId,
      notify: true,
      assignedToUserIds: [],
      taskType: this.taskTypeList[0].id,
      taskStatus: isNew ? TaskStatusType.New : newTaskData.taskStatus,
      subTasks: [],
    };

    const handleSuccess = (message: string) => {
      this.spinnerService.hide();
      this.toasterService.success(message, '', this.commonService.toasterMessageConfiguration);
    };

    const handleError = (error: any) => {};

    if (isNew) {
      this.taskService.create(param).subscribe(res => {
        handleSuccess(NotificationTextMessage.taskAddedMessage);
        this.store.dispatch(new AddSubTask(res));
      }, handleError);
    } else {
      this.taskService.update(newTaskData.id, param).subscribe(res => {
        handleSuccess(NotificationTextMessage.taskUpdatedMessage);

        this.store.dispatch(new UpdateSubTask(rowIndex, res));
      }, handleError);
    }

    sender.closeRow(rowIndex);
  }

  onCancelSubTask({ sender, rowIndex }: CancelEvent): void {
    this.closeEditor(sender, rowIndex);
  }

  onEditSubTask({ sender, rowIndex, dataItem }: EditEvent): void {
    this.closeEditor(sender);
    this.formGroup = createSubTaskFormGroup(dataItem);
    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.formGroup);
  }

  closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex): void {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  allSubTaskCompleted(): boolean {
    let isAllCompleted = false;

    this.subTaskList$
      .pipe(
        map(subtaskList => {
          if (subtaskList.length <= 0) {
            return true;
          }
          return subtaskList.every(subtask => subtask.taskStatus === TaskStatusType.Completed);
        }),
      )
      .subscribe(result => {
        isAllCompleted = result;
      });

    return isAllCompleted;
  }

  checkIsDisable(): boolean {
    var isDisable;
    this.subTaskList$
      .pipe(
        map(
          subtaskList =>
            subtaskList.length > 0 &&
            subtaskList.every(subtask => subtask.taskStatus === TaskStatusType.Completed),
        ),
      )
      .subscribe(x => {
        isDisable = x;
      });
    return isDisable;
  }

  getSubtask(): void {
    if (this.loadStateData) {
      this.store.select(TaskState.getSubTaskList).subscribe();
    } else {
      let param: any = {
        sorting: '',
        maxResultCount: 1000,
        skipCount: 0,
      };
      this.store.dispatch(new GetSubTaskList(this.taskId, param)).subscribe();
    }
    this.isAllSubTaskCompleted = this.checkIsDisable();
  }
}
