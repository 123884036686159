<div class="q-content">
  <div class="q-content-bg pb-0">
    <div>
      <div class="border-bottom">
        <div
          class="font-size-18 fw-500 mb-20 d-flex gap-15 align-items-center justify-content-between"
        >
          <div class="d-flex gap-10 align-items-center">
            <span class="task-icon"><i class="far fa-clipboard-list font-size-14"></i></span
            ><span>Task Details</span>
          </div>
          <div>
            <span class="d-flex gap-15 justify-content-end text-primary">
              <a
                href="javascript:void(0)"
                (click)="panelId || tabId ? redirectToTaskList($event, 'back') : back()"
              >
                <i class="fa fa-arrow-left" aria-hidden="true"></i> Back
              </a>
            </span>
          </div>
        </div>
      </div>
      <div>
        <div class="d-flex task-details-height">
          <div>
            <div class="pt-2 left-side-details border-right">
              <form [formGroup]="taskForm" validateOnSubmit class="k-form k-form-md">
                <div class="mb-2">
                  <kendo-label
                    text="Task List"
                    class="fw-500 mb-5 display-inline"
                    labelCssClass="k-form-label"
                  ></kendo-label>
                  <div>
                    <a
                      (click)="redirectToTaskList($event, 'taskList')"
                      class="text-primary word-break"
                    >
                      {{ taskDetails?.taskListName }}
                    </a>
                  </div>
                </div>
                <div class="pb-2">
                  <kendo-label
                    text="Assigned To"
                    class="fw-500 mb-10 display-inline mr-5"
                    labelCssClass="k-form-label"
                  ></kendo-label>
                  <div
                    *ngIf="!isShowAssigneeMultiSelect"
                    (click)="isShowAssigneeMultiSelect = true"
                  >
                    <div
                      *ngIf="taskDetails?.users?.length === 0; else assigneeTemplate"
                      class="d-flex align-items-center"
                    >
                      <i class="fal fa-circle-user mr-10 font-size-22"></i>
                    </div>
                    <ng-template #assigneeTemplate>
                      <ng-container *ngIf="taskDetails?.users && taskDetails?.users?.length > 0">
                        <ng-container *ngFor="let user of taskDetails?.users">
                          <kendo-avatar
                            rounded="full"
                            size="large"
                            [border]="true"
                            [initials]="commonService.getInitialsAndAvtarColor(user).avatar"
                            [style.backgroundColor]="
                              commonService.getInitialsAndAvtarColor(user).generateAvtarColor
                            "
                            class="social-profile-text assignee-container"
                            [attr.title]="commonService.getInitialsAndAvtarColor(user).userName"
                            class="social-profile-text assignee-profile"
                          ></kendo-avatar>
                        </ng-container>
                      </ng-container>
                    </ng-template>
                  </div>
                  <kendo-multiselect
                    *ngIf="isShowAssigneeMultiSelect"
                    [clearButton]="false"
                    [checkboxes]="true"
                    [data]="assigneeList"
                    valueField="userId"
                    textField="userName"
                    formControlName="assignee"
                    [ngModelOptions]="{ standalone: true }"
                    (close)="isShowAssigneeMultiSelect = false"
                    (valueChange)="onSave()"
                    [filterable]="true"
                    (filterChange)="onAssigneeSearch($event)"
                  ></kendo-multiselect>
                </div>
                <div class="pb-2">
                  <kendo-label
                    text="Start Date"
                    class="fw-500 mb-5 display-inline"
                    labelCssClass="k-form-label"
                  ></kendo-label>
                  <div *ngIf="!isShowStartDateInput" (click)="isShowStartDateInput = true">
                    <div
                      *ngIf="
                        this.taskForm.controls.startDate?.value === '' ||
                          this.taskForm.controls.startDate?.value === null;
                        else startDate
                      "
                    >
                      <i class="fal fa-calendar-circle-plus font-size-18"></i>
                    </div>
                    <ng-template #startDate>
                      <div>
                        {{ this.taskForm.controls.startDate?.value | date: 'EEE, d MMM yyyy' }}
                      </div>
                    </ng-template>
                  </div>

                  <kendo-datepicker
                    *ngIf="isShowStartDateInput"
                    placeholder="Choose a date ..."
                    [format]="'EEE, d MMM yyyy'"
                    (close)="isShowStartDateInput = false"
                    (valueChange)="onSave()"
                    formControlName="startDate"
                    calendarType="classic"
                  ></kendo-datepicker>
                </div>
                <div class="pb-2">
                  <kendo-label
                    text="Due Date"
                    class="fw-500 mb-5 display-inline"
                    labelCssClass="k-form-label"
                  ></kendo-label>
                  <div *ngIf="!isShowDueDateInput" (click)="isShowDueDateInput = true">
                    <div
                      *ngIf="
                        this.taskForm.controls.dueDate?.value === '' ||
                          this.taskForm.controls.dueDate?.value === null;
                        else dueDate
                      "
                    >
                      <i class="fal fa-calendar-circle-plus font-size-18"></i>
                    </div>
                    <ng-template #dueDate>
                      <div>
                        {{ this.taskForm.controls.dueDate?.value | date: 'EEE, d MMM yyyy' }}
                      </div>
                    </ng-template>
                  </div>

                  <kendo-datepicker
                    *ngIf="isShowDueDateInput"
                    placeholder="Choose a date ..."
                    [format]="'EEE, d MMM yyyy'"
                    (close)="isShowDueDateInput = false"
                    (valueChange)="onSave()"
                    formControlName="dueDate"
                    calendarType="classic"
                  ></kendo-datepicker>
                </div>
              </form>
              <div class="pb-2">
                <kendo-label
                  text="Created By"
                  class="fw-500 mb-5 display-inline"
                  labelCssClass="k-form-label"
                ></kendo-label>
                <div class="mb-5 d-flex align-items-center">
                  <kendo-avatar
                    rounded="full"
                    size="large"
                    [border]="true"
                    [initials]="commonService.getInitials(taskDetails?.creatorName)"
                    [style.backgroundColor]="
                      commonService.generateAvtarColor(
                        taskDetails?.creatorId,
                        taskDetails?.creatorName
                      )
                    "
                    class="social-profile-text task-detail-avatar"
                  ></kendo-avatar
                  ><span class="ml-5 word-break">{{ taskDetails?.creatorName }}</span>
                </div>
                <p>{{ taskDetails?.creationTime | date: 'EEE, d MMM yyyy' }}</p>
              </div>
              <div class="pb-2">
                <kendo-label
                  text="Updated By"
                  class="fw-500 mb-5 display-inline"
                  labelCssClass="k-form-label"
                ></kendo-label>
                <div
                  class="mb-5 d-flex align-items-center"
                  *ngIf="taskDetails?.lastModifierUserName"
                >
                  <kendo-avatar
                    rounded="full"
                    size="large"
                    [border]="true"
                    [initials]="commonService.getInitials(taskDetails?.lastModifierUserName)"
                    [style.backgroundColor]="
                      commonService.generateAvtarColor(
                        taskDetails.creatorId,
                        taskDetails?.lastModifierUserName
                      )
                    "
                    class="social-profile-text task-detail-avatar"
                  ></kendo-avatar>
                  <span class="ml-5 word-break">{{ taskDetails?.lastModifierUserName }}</span>
                </div>
                <ng-container *ngIf="taskDetails?.lastModifierUserName; else noUpdateBy">
                  <p>{{ taskDetails.lastModificationTime | date: 'EEE, d MMM yyyy' }}</p>
                </ng-container>
                <ng-template #noUpdateBy>
                  <p>-</p>
                </ng-template>
              </div>
              <div class="pb-2">
                <kendo-label
                  text="Task ID"
                  class="fw-500 mb-5 display-inline"
                  labelCssClass="k-form-label"
                ></kendo-label>
                <p class="mb-0">{{ taskId }}</p>
              </div>
            </div>
          </div>

          <div class="right-side-details">
            <div>
              <div class="pt-2">
                <div class="primary-card mb-20">
                  <div class="d-flex justify-content-between gap-15 task-details-section">
                    <div class="d-flex gap-10 align-items-start">
                      <input
                        type="checkbox"
                        kendoCheckBox
                        class="billable-checkbox"
                        size="large"
                        (change)="onTaskCompleteChange($event)"
                        [checked]="(taskStatus$ | async) === taskStatusType.Completed"
                        #notification
                        kendoCheckBox
                      />
                      <div>
                        <div class="d-flex align-items-start mb-15">
                          <ng-container
                            *ngIf="taskDetails?.users && taskDetails?.users?.length > 0"
                          >
                            <ng-container *ngFor="let user of taskDetails?.users">
                              <kendo-avatar
                                rounded="full"
                                size="large"
                                [border]="true"
                                [initials]="commonService.getInitials(user.userName)"
                                [style.backgroundColor]="
                                  commonService.generateAvtarColor(user.userId, user.userName)
                                "
                                [attr.title]="user.userName"
                                class="social-profile-text assignee-profile mr-10"
                              ></kendo-avatar>
                            </ng-container>
                          </ng-container>
                          <ng-container *ngIf="taskDetails?.users?.length < 0">
                            <i class="fal fa-circle-user font-size-24"></i>
                          </ng-container>
                          <span
                            class="text-primary display-inline line-height-normal task-name"
                            title="{{ taskDetails?.name }}"
                            >{{ taskDetails?.name }}</span
                          >
                        </div>
                        <div class="d-flex flex-wrap gap-15">
                          <div
                            class="d-flex align-items-center flex-wrap"
                            *ngIf="taskDetails?.startdate || taskDetails?.duedate"
                          >
                            <span class="font-size-12"
                              >{{ taskDetails?.startdate | date: 'd-MMM-yyyy' }}
                            </span>
                            <span
                              class="ml-5 mr-5"
                              *ngIf="taskDetails?.startdate && taskDetails?.duedate"
                            >
                              -
                            </span>
                            <span class="font-size-12 due-date">
                              {{ taskDetails?.duedate | date: 'd-MMM-yyyy' }}
                            </span>
                          </div>
                          <div class="d-flex flex-wrap gap-10">
                            <span *ngIf="taskDetails?.estimateMinutes" class="q-label font-size-12"
                              ><i class="far fa-stopwatch mr-5"></i> Est:
                              {{
                                commonService.getHoursAndMinutesFromTotalMinutes(
                                  taskDetails?.estimateMinutes
                                )
                              }}</span
                            >
                            <span
                              *ngIf="(totalLoggedTime$ | async) > 0"
                              class="q-label font-size-12"
                              ><i class="far fa-clock mr-5"></i> Log:
                              {{
                                commonService.getHoursAndMinutesFromTotalMinutes(
                                  totalLoggedTime$ | async
                                )
                              }}</span
                            >

                            <span
                              *ngIf="this.taskDetails?.columnId !== null"
                              class="q-label font-size-12"
                              >{{ this.taskDetails?.projectColumnName }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex gap-15 time-manage-button">
                      <button kendoButton (click)="openTimeLog(taskDetails?.name)">
                        <i class="fa-regular fa-clock font-size-14 mr-5"></i> Log time
                      </button>
                      <button
                        kendoButton
                        (click)="onStartTimerClick()"
                        fillMode="outline"
                        [disabled]="isTimerRunning"
                      >
                        <i class="far fa-stopwatch font-size-14 mr-5"></i> Start timer
                      </button>
                      <kendo-dropdownbutton
                        [data]="timerActionList"
                        #additionalSetting
                        (itemClick)="onActionItemClicked($event)"
                        ><i class="fas fa-ellipsis-v"></i>
                      </kendo-dropdownbutton>
                    </div>
                  </div>
                </div>
              </div>

              <div class="pb-1 mb-20" *ngIf="taskDetails?.description">
                <div class="description-section">
                  <span [innerHTML]="safeDescription"></span>
                </div>
              </div>

              <div class="pb-1 mb-20 border-bottom">
                <kendo-label
                  labelCssClass="k-form-label font-size-16 mb-5"
                  text="Sub Tasks"
                ></kendo-label>
                <app-sub-tasks
                  [taskId]="taskId"
                  [sortingName]="sortingName"
                  [taskListId]="taskListId"
                  [loadStateData]="false"
                ></app-sub-tasks>
              </div>

              <div class="pb-1 mb-20 border-bottom">
                <kendo-label
                  labelCssClass="k-form-label font-size-16 mb-5"
                  text="Files"
                ></kendo-label>
                <app-files
                  [taskId]="taskId"
                  [taskName]="taskDetails?.name"
                  [loadStateData]="false"
                ></app-files>
              </div>
              <div class="pb-1 mb-20 border-bottom">
                <kendo-label
                  labelCssClass="k-form-label font-size-16 mb-5"
                  text="Time Logs"
                ></kendo-label>
                <!-- <app-time-log-grid
                  [taskId]="taskId"
                  [projectId]="projectId"
                  [isProjectBillable]="isProjectBillable"
                  [taskName]="taskDetails?.name"
                  [loadStateData]="false"
                ></app-time-log-grid> -->
                <app-time
                  [taskId]="taskId"
                  [projectId]="projectId"
                  [allowGrouping]="false"
                  [taskName]="taskDetails?.name"
                  [loadStateData]="false"
                  [showTimeLogTaskDetail]="true"
                >
                </app-time>
              </div>
              <div class="mb-20">
                <kendo-label
                  labelCssClass="k-form-label font-size-16 mb-20"
                  text="Comments"
                ></kendo-label>
                <app-comment [taskId]="taskId"></app-comment>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div kendoDialogContainer></div>

<kendo-drawer-container class="add-more-details">
  <kendo-drawer
    [mode]="expandMode"
    [expanded]="isDrawerOpened"
    [position]="position"
    [autoCollapse]="false"
  >
    <ng-template kendoDrawerTemplate>
      <app-add-task-more-details
        [taskId]="taskId"
        [projectId]="projectId"
        [assigneListItems]="assigneListItems"
        [sortingName]="sorting"
        (reloadTaskList)="onTaskDrawerClose($event)"
        [loadStateData]="true"
        [isFromEditAction]="isFromEditAction"
        [drawerSelectedTabId]="drawerSelectedTabId"
      ></app-add-task-more-details>
    </ng-template>
  </kendo-drawer>
</kendo-drawer-container>
