<div
  *ngIf="commentList.length === 0; else commentTemplate"
  class="d-flex justify-content-center align-items-center flex-column mt-2"
>
  <img src="assets/images/comment.png" alt="comment" />
  <div class="mb-2 mt-1 text-align-center">
    <p class="mb-5">No comments</p>
    <p class="text-secondary-text font-size-12">
      No comments have been added to this task. Add the first comment in the box below.
    </p>
  </div>
</div>
<ng-template #commentTemplate>
  <div class="comments-container">
    <div
      *ngFor="let comment of commentList; let last = last"
      class="list-of-comments-container mb-20"
      [ngClass]="{ 'no-border': last }"
      [hidden]="editingCommentId === comment.id"
    >
      <div class="d-flex justify-content-between gap-10">
        <div class="d-flex gap-10">
          <kendo-avatar
            rounded="full"
            size="large"
            [border]="true"
            [initials]="commonService.getInitials(comment.creatorName)"
            [style.backgroundColor]="
              commonService.generateAvtarColor(comment.creatorId, comment.creatorName)
            "
            class="social-profile-text task-detail-avatar user-avatar"
          ></kendo-avatar>
          <div class="comment-metadata d-flex flex-column">
            <span class="creator-name font-size-14 text-black">{{ comment.creatorName }}</span>
            <span class="comment-date">{{ formatDate(comment.creationTime) }}</span>
          </div>
        </div>
        <div class="action-dropdown" *ngIf="comment.canEditOrDelete">
          <kendo-dropdownbutton
            [data]="commentActionItem"
            class="dropdown-button ml-10"
            (itemClick)="onCommentActionClick($event, comment.id)"
            (click)="stopPropagation($event)"
            ><i class="fas fa-ellipsis-v"></i>
          </kendo-dropdownbutton>
        </div>
      </div>
      <div class="comment-body">
        <p [innerHTML]="getSanitizedHtml(comment.htmlBody)"></p>
      </div>
    </div>
  </div>
</ng-template>
<div class="comment-container d-flex align-items-start">
  <div class="avatar-container">
    <kendo-avatar
      rounded="full"
      size="large"
      [border]="true"
      [initials]="commonService.getInitials(currentUser.name + ' ' + currentUser.surName)"
      [style.backgroundColor]="
        commonService.generateAvtarColor(
          currentUser.id,
          currentUser.name + ' ' + currentUser.surName
        )
      "
      class="social-profile-text task-detail-avatar"
    ></kendo-avatar>
  </div>

  <div class="editor-container flex-grow-1">
    <textarea
      *ngIf="!isEditorActive"
      class="initial-textbox"
      placeholder="Add a comment"
      (focus)="activateEditor()"
    ></textarea>

    <ng-container *ngIf="isEditorActive">
      <kendo-editor
        class="mb-20 comment-kendo-editor"
        [(ngModel)]="commentText"
        placeholder="Add a comment"
        [iframeCss]="{ content: customStyleContent }"
        [tools]="customTools"
      ></kendo-editor>

      <div class="d-flex justify-content-between mt-1">
        <div class="notify-section d-flex align-items-center" *ngIf="false">
          <span>Notify:</span>
          <span class="notify-avatar">VU</span>
          <button kendoButton icon="plus" class="add-button"></button>
          <button kendoButton icon="attachment" class="attachment-button"></button>
          <button kendoButton icon="lock" class="lock-button"></button>
        </div>
        <div class="d-flex gap-10 flex-wrap">
          <button kendoButton (click)="cancelEdit()" fillMode="outline">Cancel</button>
          <button kendoButton (click)="saveComment()">
            {{ updateCommentId ? 'Update' : 'Save' }}
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
