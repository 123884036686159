import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

export class TaskDateDto {
  year: number;
  month: number;
  day: number;

  constructor(year: number, month: number, day: number) {
    this.year = year;
    this.month = month;
    this.day = day;
  }
}

@Component({
  selector: 'app-set-assignee',
  templateUrl: './set-assignee.component.html',
  styleUrls: ['./set-assignee.component.scss'],
})
export class SetAssigneeComponent implements OnInit {
  @Input() showAssigneePopup: boolean = false;
  @Input() position: { top: number; left: number } | null = null;
  @Input() projectId: any;
  @Input() taskData: any;
  @Input() assigneeList: any[] = [];
  @Input() assigneListItems: any[] = [];
  @Input() selectedAssignee: any;
  @Output() triggerUpdatedAssignee = new EventEmitter<any>();

  assigneeForm: FormGroup;

  constructor() {}

  ngOnInit(): void {
    this.setForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.assigneeForm && changes.selectedAssignee) {
      this.assigneeForm.patchValue({
        assignee: this.selectedAssignee?.length ? this.selectedAssignee : null,
      });
    }
  }

  setForm(): void {
    this.assigneeForm = new FormGroup({
      assignee: new FormControl(null),
    });
  }

  onAssigneeSearch(searchTerm: string): void {
    const contains = (value: string) => (item: { userName: string; userId: number }) =>
      item.userName.toLowerCase().includes(value.toLowerCase());

    this.assigneeList = this.assigneListItems.filter(contains(searchTerm));
  }

  onSubmitAssignee(): void {
    this.triggerUpdatedAssignee.next(this.assigneeForm.controls.assignee.value);
  }
}
