import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  CreateUpdateTaskDto,
  TaskStatusType,
  TaskType,
} from 'projects/task-service/src/lib/proxy/task-service';
import { CommonService } from 'src/core/services';
import { TaskService } from 'projects/task-service/src/lib/proxy/task-service/task/task.service';
import { ToasterService } from '@abp/ng.theme.shared';
import { NotificationTextMessage } from 'src/app/enum/notification';

@Component({
  selector: 'app-add-task',
  templateUrl: './add-task.component.html',
  styleUrls: ['./add-task.component.scss'],
})
export class AddTaskComponent implements OnInit {
  formGroup: FormGroup;
  @Input() taskListId: any;
  constructor(
    private commonService: CommonService,
    private dialog: DialogRef,
    private fb: FormBuilder,
    private spinnerService: NgxSpinnerService,
    private taskService: TaskService,
    private toasterService: ToasterService,
  ) {}

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      taskName: ['', [Validators.required]],
    });
  }

  onAddTaskClick(): void {
    if (this.formGroup.controls.taskName.invalid) {
      this.formGroup.controls.taskName.markAsTouched();
      return;
    }
    this.spinnerService.show();

    // const estimateTime = `${
    //   this.taskForm.value.estimateHour === '' ? 0 : this.taskForm.value.estimateHour
    // }:${this.taskForm.value.estimateMinute === '' ? 0 : this.taskForm.value.estimateMinute}`;

    const estimateMinutes = this.commonService.getTotalMinutesFromHoursAndMinutes('0');
    const param: CreateUpdateTaskDto = {
      isQuickEdit: true,
      id: 0,
      name: this.formGroup.controls.taskName.value,
      description: '',
      progress: 20,
      taskListId: this.taskListId,
      startdate: null,
      duedate: null,
      priority: 0,
      estimateMinutes: estimateMinutes,
      parentTaskId: null,
      notify: true,
      assignedToUserIds: [],
      taskType: TaskType.Task,
      taskStatus: TaskStatusType.New,
      subTasks: [],
    };

    this.taskService.create(param).subscribe(
      res => {
        this.spinnerService.hide();
        this.toasterService.success(
          NotificationTextMessage.taskAddedMessage,
          '',
          this.commonService.toasterMessageConfiguration,
        );
        this.commonService.onDialogClose(this.dialog, true);
      },
      err => {
        this.spinnerService.hide();
      },
    );
  }

  onCloseClick(): void {
    this.commonService.onDialogClose(this.dialog, false);
  }
}
