<kendo-popup
  *ngIf="showTaskFilterPopup"
  [offset]="position"
  (click)="$event.stopPropagation()"
  [popupAlign]="popupAlign"
  class="filter-container"
>
  <div class="k-menu-popup pt-0 pb-10 pl-10 pr-10">
    <p class="font-size-16 fw-500 text-primary mb-10">Choose filters:</p>
    <form [formGroup]="filterForm">
      <div class="filter-dropdowns">
        <kendo-formfield class="mb-20 d-block" *ngIf="!showProjectSummary">
          <kendo-label text="Assignee" labelCssClass="k-form-label"></kendo-label>
          <kendo-multiselect
            [clearButton]="false"
            [checkboxes]="true"
            [data]="assigneeList"
            valueField="userId"
            textField="userName"
            [ngModelOptions]="{ standalone: true }"
            [filterable]="true"
            (filterChange)="onAssigneeSearch($event)"
            formControlName="assignee"
          ></kendo-multiselect>
        </kendo-formfield>
        <kendo-formfield class="mb-20 d-block">
          <kendo-label text="Date" labelCssClass="k-form-label"></kendo-label>
          <kendo-dropdownlist
            [data]="dateFilter"
            textField="text"
            valueField="value"
            [value]="selectedDateFilterValue"
            formControlName="date"
            [valuePrimitive]="true"
            (selectionChange)="selectionChange($event)"
          >
            <ng-template kendoDropDownListItemTemplate let-dataItem>
              {{ dataItem.text }}
            </ng-template>
          </kendo-dropdownlist>
        </kendo-formfield>

        <div *ngIf="selectedDateFilterValue === 1">
          <kendo-formfield class="mb-20 d-block">
            <kendo-label text="Start Date" labelCssClass="k-form-label"></kendo-label>
            <kendo-datepicker
              calendarType="classic"
              [format]="'EEE, d MMM yyyy'"
              (valueChange)="updateStartDate($event)"
              formControlName="startDate"
            ></kendo-datepicker>
          </kendo-formfield>
          <kendo-formfield class="mb-20 d-block">
            <kendo-label text="End Date" labelCssClass="k-form-label"></kendo-label>
            <kendo-datepicker
              calendarType="classic"
              [format]="'EEE, d MMM yyyy'"
              (valueChange)="updateEndDate($event)"
              formControlName="dueDate"
            ></kendo-datepicker>
            <div *ngIf="dateError" class="text-danger">
              End Date cannot be earlier than Start Date.
            </div>
          </kendo-formfield>
        </div>
        <kendo-formfield class="mb-20 d-block">
          <kendo-label text="Task Status" labelCssClass="k-form-label"></kendo-label>
          <kendo-dropdownlist
            [data]="taskStatusTypeList"
            textField="value"
            valueField="key"
            placeholder="Select Status"
            formControlName="taskStatus"
            [valuePrimitive]="true"
          ></kendo-dropdownlist>
        </kendo-formfield>
        <kendo-formfield class="mb-20 d-block">
          <kendo-label text="Priority" labelCssClass="k-form-label"></kendo-label>
          <kendo-dropdownlist
            [data]="priorityList"
            textField="text"
            valueField="id"
            [valuePrimitive]="true"
            formControlName="priority"
          >
          </kendo-dropdownlist>
        </kendo-formfield>
      </div>
    </form>

    <div class="quick-filters mb-20">
      <p class="font-size-16 fw-500 text-primary mb-10">Quick filters:</p>
      <div class="quick-buttons d-flex gap-10 flex-wrap">
        <button
          [ngClass]="{ 'applied-quick-filter': togglecompletedOnly }"
          (click)="onClickQuickFilterComplete()"
        >
          Completed tasks
        </button>
        <button
          [ngClass]="{ 'applied-quick-filter': toggleIncompleteAndcompletedOnly }"
          (click)="onClickQuickFilterInComplete()"
        >
          Incomplete + completed tasks
        </button>
        <button
          [ngClass]="{ 'applied-quick-filter': toggleCreatedByMe }"
          (click)="onClickQuickFilterCreatedByMe()"
        >
          Created by me
        </button>
        <button
          [ngClass]="{ 'applied-quick-filter': toggleisLateTask }"
          (click)="onClickQuickFilterLateTask()"
        >
          Late tasks
        </button>
        <button
          [ngClass]="{ 'applied-quick-filter': toggleDueThisWeek }"
          (click)="onClickQuickFilterDueThisWeekTask()"
        >
          Due this week
        </button>
        <button
          [ngClass]="{ 'applied-quick-filter': toggleDueNextWeek }"
          (click)="onClickQuickFilterDueNextWeekTask()"
        >
          Due next week
        </button>
      </div>
    </div>

    <div class="d-flex justify-content-end gap-10">
      <button kendoButton (click)="onSubmit()">Submit</button>

      <button kendoButton fillMode="outline" (click)="clearFilter()">Clear All</button>
    </div>
  </div>
</kendo-popup>
