import { ConfigStateService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommentService } from 'projects/comment-service/src/lib/proxy/comment-service';
import { Observable } from 'rxjs';
import { GetCommentList } from 'src/app/core/store/task.action';
import { TaskState } from 'src/app/core/store/task.state';
import { CommentActionEnum, CommentObjectType } from 'src/app/enum/comment-object-type-enum';
import { NotificationTextMessage } from 'src/app/enum/notification';
import { TaskListActionEnumEnum } from 'src/app/enum/tasklist-action-filter';
import { CommonService } from 'src/core/services';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent implements OnInit {
  customStyleContent = `
  .k-content * {
    font-family: 'Poppins', sans-serif;
    color: #404a5f;
  }

  .k-content .k-placeholder {
    font-size: 13px;
    color: #909EB2;
  }`;

  @Input() taskId: any;
  @Output() triggerCommentCount = new EventEmitter();

  commentList: any[] = [];
  updateCommentId: any;
  currentUser: any;

  commentText = '';

  activeCommentId: number | null = null;
  editingCommentId: number | null = null;

  isEditorActive = false;

  commentActionItem = [
    {
      text: TaskListActionEnumEnum[TaskListActionEnumEnum.Edit],
      iconClass: 'far fa-pen',
    },
    {
      text: TaskListActionEnumEnum[TaskListActionEnumEnum.Delete],
      iconClass: 'far fa-trash-alt',
    },
  ];

  constructor(
    private spinnerService: NgxSpinnerService,
    private commentService: CommentService,
    private toasterService: ToasterService,
    private sanitizer: DomSanitizer,
    private config: ConfigStateService,
    public commonService: CommonService,
    private store: Store,
  ) {
    this.currentUser = this.config.getOne('currentUser');
  }

  ngOnInit(): void {
    this.getComments();
  }

  onCommentActionClick(data, commentId) {
    const action = data.text;

    switch (action) {
      case CommentActionEnum[CommentActionEnum.Edit]:
        this.editComment(commentId);
        break;
      case CommentActionEnum[CommentActionEnum.Delete]:
        this.deleteComment(commentId);
        break;
    }
  }

  activateEditor() {
    this.isEditorActive = true;
  }

  cancelEdit() {
    this.isEditorActive = false;
    this.editingCommentId = null;
    this.updateCommentId = null;
    this.commentText = '';
  }

  getSanitizedHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  saveComment(): void {
    if (this.commentText === '') {
      this.toasterService.info(
        'Please add comment',
        '',
        this.commonService.toasterMessageConfiguration,
      );

      return;
    }
    this.isEditorActive = false;
    this.spinnerService.show();
    const plainTextComment = this.commentText.replace(/<[^>]*>/g, '');
    const createUpdateCommentParam: any = {
      body: plainTextComment,
      isPrivate: true,
      objectId: this.taskId,
      objectType: CommentObjectType.Task,
      htmlBody: this.commentText,
      lockdownId: 0,
      contentType: 0,
    };
    if (this.updateCommentId !== null && this.updateCommentId !== undefined) {
      this.commentService.update(this.updateCommentId, createUpdateCommentParam).subscribe(
        res => {
          if (res) {
            this.spinnerService.hide();
            this.toasterService.success(
              NotificationTextMessage.commentUpdatedMessage,
              '',
              this.commonService.toasterMessageConfiguration,
            );
            this.commentText = '';
            this.editingCommentId = null;
            this.updateCommentId = null;
            this.getComments();
          }
        },
        err => {
          this.spinnerService.hide();
        },
      );
    } else {
      this.commentService.create(createUpdateCommentParam).subscribe(
        res => {
          if (res) {
            this.spinnerService.hide();
            this.toasterService.success(
              NotificationTextMessage.commentAddedMessage,
              '',
              this.commonService.toasterMessageConfiguration,
            );
            this.commentText = '';
            this.editingCommentId = null;
            this.getComments();
          }
        },
        err => {
          this.spinnerService.hide();
        },
      );
    }
  }

  editComment(commentId: any): void {
    this.activeCommentId = null;
    this.spinnerService.show();
    if (commentId) {
      this.commentService.get(commentId).subscribe(res => {
        this.editingCommentId = res.id;
        this.commentText = res.htmlBody;
        this.updateCommentId = res.id;
        this.spinnerService.hide();
      });
      this.isEditorActive = true;
    }
  }

  deleteComment(commentId: any): void {
    this.activeCommentId = null;
    if (commentId) {
      this.commentService.delete(commentId).subscribe(res => {
        this.toasterService.success(NotificationTextMessage.commentDelete, '', {});
        this.getComments();
      });
    }
  }

  getComments(): void {
    this.spinnerService.show();
    const params = {
      taskId: this.taskId,
      sorting: null,
      skipCount: null,
      maxResultCount: null,
    };
    this.store.dispatch(new GetCommentList(params)).subscribe(res => {
      this.spinnerService.hide();
      this.commentList = [];
      const data = res['task'];

      if (data.commentList.length > 0) {
        this.commentList = data.commentList;
        this.triggerCommentCount.next(data.commentCount);
      } else {
        this.triggerCommentCount.next(0);
      }
    });
  }

  formatDate(dateStr: string): string {
    const date: Date = new Date(dateStr);
    const now: Date = new Date();

    if (isNaN(date.getTime())) {
      return 'Invalid date';
    }

    const diffInMs: number = now.getTime() - date.getTime();
    const diffInSec: number = Math.floor(diffInMs / 1000);

    const secondsInMinute = 60;
    const secondsInHour = 3600;
    const secondsInDay = 86400;
    const secondsInMonth = 2592000;
    const secondsInYear = 31536000;

    if (diffInSec < secondsInMinute) {
      return `${diffInSec} seconds ago`;
    } else if (diffInSec < secondsInHour) {
      return `${Math.floor(diffInSec / secondsInMinute)} minutes ago`;
    } else if (diffInSec < secondsInDay) {
      return `${Math.floor(diffInSec / secondsInHour)} hours ago`;
    } else if (diffInSec < secondsInMonth) {
      return `${Math.floor(diffInSec / secondsInDay)} days ago`;
    } else if (diffInSec < secondsInYear) {
      return `${Math.floor(diffInSec / secondsInMonth)} months ago`;
    } else {
      return `${Math.floor(diffInSec / secondsInYear)} years ago`;
    }
  }
}
