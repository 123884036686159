<div class="mb-20">
  <h5 class="mb-20">Over Time Tasks</h5>
  <div class="grid-wrapper">
    <kendo-grid
      [kendoGridBinding]="overTimeTasksList"
      [pageSize]="1000"
      [height]="gridHeight"
      [sortable]="{
        allowUnsort: true,
        mode: multiple ? 'multiple' : 'single',
      }"
      [sort]="sort"
      (sortChange)="sortChange($event)"
      class="custom-no-records-container"
    >
      <ng-template kendoGridNoRecordsTemplate>
        <div *ngIf="this.overTimeTasksList?.length === 0" class="no-records-text-container">
          No Records Available
        </div>
      </ng-template>

      <kendo-grid-column field="taskName" title="Task Name" [width]="250">
        <ng-template kendoGridCellTemplate let-dataItem>
          <a
            class="wmx-100 clamp text-underline"
            (click)="onGoToTaskDetailsClick(dataItem, dataItem.taskListId)"
            title="{{ dataItem.taskName }}"
            ><span>{{ dataItem.taskName }}</span></a
          >
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="estimateMinutes" title="Estimated Time" [width]="80">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="d-flex align-items-center justify-content-between">
            <div class="customer-name">
              {{ commonService.getHoursAndMinutesFromTotalMinutes(dataItem.estimateMinutes) }}
            </div>
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="totalMinutes" title="Spend Time" [width]="80">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="d-flex align-items-center justify-content-between">
            <div class="customer-name">
              {{ commonService.getHoursAndMinutesFromTotalMinutes(dataItem.totalMinutes) }}
            </div>
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="overTime" title="Over Time" [width]="80">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="d-flex align-items-center justify-content-between">
            <div class="customer-name">
              {{ commonService.getHoursAndMinutesFromTotalMinutes(dataItem.overTime) }}
            </div>
          </div>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</div>
<div>
  <h5 class="mb-20">Left Time Tasks</h5>
  <div class="grid-wrapper">
    <kendo-grid
      [kendoGridBinding]="leftTimeTasksList"
      [pageSize]="1000"
      [height]="gridHeight"
      [sortable]="{
        allowUnsort: true,
        mode: multiple ? 'multiple' : 'single',
      }"
      [sort]="sort"
      (sortChange)="sortChange($event)"
      class="custom-no-records-container"
    >
      <ng-template kendoGridNoRecordsTemplate>
        <div *ngIf="this.leftTimeTasksList?.length === 0" class="no-records-text-container">
          No Records Available
        </div>
      </ng-template>

      <kendo-grid-column field="taskName" title="Task Name" [width]="250">
        <ng-template kendoGridCellTemplate let-dataItem>
          <a
            class="wmx-100 clamp text-underline"
            (click)="onGoToTaskDetailsClick(dataItem, dataItem.taskListId)"
            title="{{ dataItem.taskName }}"
            ><span>{{ dataItem.taskName }}</span></a
          >
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="estimateMinutes" title="Estimated Time" [width]="80">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="d-flex align-items-center justify-content-between">
            <div class="customer-name">
              {{ commonService.getHoursAndMinutesFromTotalMinutes(dataItem.estimateMinutes) }}
            </div>
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="totalMinutes" title="Spend Time" [width]="80">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="d-flex align-items-center justify-content-between">
            <div class="customer-name">
              {{ commonService.getHoursAndMinutesFromTotalMinutes(dataItem.estimateMinutes) }}
            </div>
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="leftTime" title="Left Time" [width]="80">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="d-flex align-items-center justify-content-between">
            <div class="customer-name">
              {{ commonService.getHoursAndMinutesFromTotalMinutes(dataItem.leftTime) }}
            </div>
          </div>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</div>
<div kendoDialogContainer></div>
