import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CommonService } from 'src/core/services';

@Component({
  selector: 'app-set-an-estimate',
  templateUrl: './set-an-estimate.component.html',
  styleUrls: ['./set-an-estimate.component.scss'],
})
export class SetAnEstimateComponent implements OnInit {
  @Input() set estimateTime(value: any) {
    if (value !== undefined && value !== 0) {
      const { hours, minutes } = this.commonService.convertMinutesToHoursAndMinutes(value);
      this.estimateForm.patchValue({
        estimateHour: hours,
        estimateMinute: minutes,
      });
      this.buttonText = 'Update estimate';
    } else {
      this.resetForm();
    }
  }

  @Input() showEstimatePopup: boolean = false;
  @Input() position: { top: number; left: number } | null = null;
  @Output() triggerEstimate = new EventEmitter<any>();
  estimateForm: FormGroup;
  buttonText: string = 'Add estimate';

  constructor(private commonService: CommonService) {}

  ngOnInit(): void {
    this.setForm();
  }

  onAddEstimate(): void {
    const estimateTime = `${this.estimateForm.value.estimateHour || 0}:${
      this.estimateForm.value.estimateMinute || 0
    }`;

    if (estimateTime === '0:0') {
      return;
    }

    this.buttonText = 'Update estimate';
    this.triggerEstimate.next(estimateTime);
  }

  private setForm(): void {
    this.estimateForm = new FormGroup({
      estimateHour: new FormControl(''),
      estimateMinute: new FormControl(''),
    });
  }

  private resetForm(): void {
    this.estimateForm?.reset({
      estimateHour: '',
      estimateMinute: '',
    });
    this.buttonText = 'Add estimate';
  }
}
