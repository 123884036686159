import { DatePipe, formatDate } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from '@progress/kendo-angular-dialog';
import { GridDataResult, PagerPosition, PagerType } from '@progress/kendo-angular-grid';
import { DrawerMode, DrawerPosition } from '@progress/kendo-angular-layout';
import { NgxSpinnerService } from 'ngx-spinner';
import { TimelogGetListInput } from 'projects/task-service/src/lib/proxy/task-service/dtos';
import { TimelogService } from 'projects/task-service/src/lib/proxy/task-service/timelog/timelog.service';
import { CommonService } from 'src/core/services';
import { TimeLogPopupComponent } from '../../time-log-popup/time-log-popup.component';
import { BillableEnum, ExportFormat } from 'projects/task-service/src/lib/proxy/service/enum';
import { ConfigStateService, PagedResultDto } from '@abp/ng.core';
import { NotificationMessage, NotificationTextMessage } from 'src/app/enum/notification';
import { ToasterService } from '@abp/ng.theme.shared';
import { saveAs } from 'file-saver';
import { ProjectUserService } from '@proxy/project-service/project';
import { ProjectService } from 'projects/project-service/src/lib/proxy/project-service';
import { IdentityUserDto, IdentityUserService } from '@abp/ng.identity/proxy';
import { TaskListTabEnum } from 'src/app/enum/tasklist-tab-enum';
import { GetProjectListInputDto } from 'projects/project-service/src/lib/proxy/project-service/projects/dtos';
import { TimeComponentFilterType } from 'src/app/enum/time-filter-types-enum';
import { Store } from '@ngxs/store';
import {
  AddTimelogList,
  DeleteTimelogList,
  GetTimeLogList,
  SetTimelogList,
} from 'src/app/core/store/task.action';
import { combineLatest, map, Observable, Subscription } from 'rxjs';
import { TaskState } from 'src/app/core/store/task.state';
import swal from 'sweetalert/dist/sweetalert.min.js';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-time',
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.scss'],
})
export class TimeComponent implements OnInit, AfterViewInit {
  gridView: GridDataResult;
  summary: any;

  dateError = false;
  isDrawerOpened = false;
  openInNewTab = false;
  isAdmin: boolean = false;
  info = true;
  pageSizes = true;
  previousNext = true;
  isBillableColumnVisible = true;
  isFilteredDataAvailable = false;
  showFilterDate: boolean = false;
  showBillableFilter: boolean = false;
  isProjectBillable: boolean = false;
  isTimePage: boolean = false;

  gridHeight: number;
  projectSummary: any;

  type: PagerType = 'numeric';
  pagerposition: PagerPosition = 'bottom';
  expandMode: DrawerMode = 'overlay';
  position: DrawerPosition = 'end';

  selectedFilterValue = 1;
  selectedBillableFilterValue = 0;
  selectedBillableFilterText: string = '';
  public pageSize: number = 100;
  skip = 0;
  filterCount: number = 0;
  buttonCount = 5;

  startDate = new Date();
  endDate = new Date();
  formattedDateRange: string = '';

  userList: any[] = [];
  selectedUserIds: string[] = [];
  selectedUsers: any[] = [];
  projectList: any[] = [];
  selectedProjectIds: string[] = [];
  selectedProject: any[] = [];
  projectListItems: any[] = [];
  userListItems: any[] = [];

  peopleList = { items: [], totalCount: 0 } as PagedResultDto<IdentityUserDto>;

  exportActionList = [
    { id: 1, iconClass: 'fas fa-file-pdf', text: 'To PDF', actionTypeId: ExportFormat.PDF },
    {
      id: 2,
      iconClass: 'fas fa-file-excel',
      text: 'To Excel',
      actionTypeId: ExportFormat.Excel,
    },
  ];

  dateFilter: Array<{ text: string; value: number }> = [
    { text: 'Anytime', value: 0 },
    { text: 'Custom date range', value: 1 },
  ];

  billableFilterList: Array<{ text: string; value: number }> = [
    { text: 'Billable and non billable', value: BillableEnum.All },
    { text: 'Billable only', value: BillableEnum.Billable },
    { text: 'Non billable only', value: BillableEnum.NonBillable },
  ];

  filterTypes = TimeComponentFilterType;
  currentUser: any;

  private refreshTimeSubscription: Subscription;

  @Input() allowGrouping: boolean = true;
  @Input() taskName: string = '';
  @Input() showProjectSummary: boolean = false;
  @Input() showTimeLogTaskDetail: boolean = false;
  @Input() isDrawerEditContent: boolean = false;
  @Input() editTaskId: number = null;
  @Input() projectId: number = null;
  @Input() taskId: number = null;

  timeLogList$: Observable<any[]>;
  totalLoggedTime$: Observable<any>;
  totalLoggedNonBillableTime$: Observable<any>;
  totalLoggedBillableTime$: Observable<any>;
  totalLoggedEstimate$: Observable<any>;
  totalLoggedNotBilled$: Observable<any>;
  timeLogTotal$: Observable<any>;
  timeList$: Observable<any>;

  @ViewChild('filterContainer') filterContainer!: ElementRef;

  constructor(
    private timelogService: TimelogService,
    private spinnerService: NgxSpinnerService,
    private datePipe: DatePipe,
    public commonService: CommonService,
    private dialogService: DialogService,
    private router: Router,
    private toasterService: ToasterService,
    public projectUser: ProjectUserService,
    private projectService: ProjectService,
    private config: ConfigStateService,
    protected service: IdentityUserService,
    private store: Store,
  ) {
    var currentUserRole: any[] = this.config.getOne('currentUser').roles;
    this.isAdmin = currentUserRole.includes('admin');
    this.currentUser = this.config.getOne('currentUser');

    this.store.dispatch(new SetTimelogList());

    this.timeLogList$ = this.store.select(TaskState.getTimelogList);
    this.timeLogTotal$ = this.store.select(TaskState.getTimelogCount);
    this.timeList$ = combineLatest([this.timeLogList$, this.timeLogTotal$]).pipe(
      map(([data, total]) => ({
        data: data || [],
        total: total || 0,
      })),
    );
  }

  ngOnInit(): void {
    const currentUrl = this.router.url.split('?')[0];
    if (currentUrl === '/time') {
      this.isTimePage = true;
    }

    this.clearFilter();
    this.getProjectList();
    if (!this.isTimePage) this.getProjectSummary();
    if (this.isAdmin) this.getPeopleList();

    this.timeLogList$ = this.store.select(TaskState.getTimelogList);
    this.timeLogTotal$ = this.store.select(TaskState.getTimelogCount);
    this.totalLoggedTime$ = this.store.select(TaskState.getTotalLoggedTime);
    this.totalLoggedNonBillableTime$ = this.store.select(TaskState.getTotalLoggedNonBillableTime);
    this.totalLoggedBillableTime$ = this.store.select(TaskState.getTotalLoggedBillableTime);
    this.totalLoggedEstimate$ = this.store.select(TaskState.getTotalLoggedEstimate);
    this.totalLoggedNotBilled$ = this.store.select(TaskState.getTotalLoggedNotBilled);

    this.refreshTimeSubscription = this.commonService.refreshTimeTrigger$.subscribe(res => {
      this.getTimelogList();
    });
  }

  ngOnDestroy(): void {
    if (this.refreshTimeSubscription) {
      this.refreshTimeSubscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.updateDivHeight();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.updateDivHeight();
  }

  updateDivHeight(): void {
    if (this.filterContainer) {
      this.calculateGridHeight(this.filterContainer.nativeElement.offsetHeight);
    }
  }

  calculateGridHeight(divHeight: number): void {
    const screenHeight = window.innerHeight;
    const gridHeaderHeight = 55;
    const headerHeight = 60;
    const wrapperpadding = 40;

    this.gridHeight = screenHeight - (headerHeight + gridHeaderHeight + wrapperpadding + divHeight);
  }

  onSubmit(): void {
    if (!this.dateError) this.getData();
    document.body.style.overflow = 'auto';
  }

  onActionItemClicked(event: any): void {
    this.exportTimesheet(event.actionTypeId);
  }

  getData(): void {
    this.setDefaultValueOnSubmit();
    this.getTimelogList();
    this.updateFilterCount();
    this.isDrawerOpened = false;
  }

  setDefaultValueOnSubmit(): void {
    if (this.selectedFilterValue == 1) {
      this.validateDates();
      this.updateFormattedDateRange();
    } else {
      this.showFilterDate = false;
    }

    this.showBillableFilter = true;

    this.selectedBillableFilterText = this.billableFilterList.filter(
      (x: any) => x.value === this.selectedBillableFilterValue,
    )[0].text;
  }

  getProjectList(): void {
    const data: GetProjectListInputDto = {
      includeProjectUserInfo: true,
      include: [],
      fields: [],
      orderByCustomFieldId: 0,
      onlyStarredProjects: true,
      hideObservedProjects: true,
      includeCounts: true,
      projectStatuses: true,
      iIncludeCustomFields: true,
      searchByLetter: true,
      skipCount: this.skip,
      maxResultCount: 1000,
      projectIds: [],
    };

    this.projectService.getList(data).subscribe(
      response => {
        this.spinnerService.hide();
        if (response.items.length > 0) {
          this.projectList = response.items;
          this.projectListItems = this.projectList.map(project => ({
            name: project.name,
            id: project.id,
          }));
        }
      },
      err => {
        this.spinnerService.hide();
      },
    );
  }

  getProjectSummary(): void {
    const params: any = {
      projectIds: [this.projectId],
    };
    this.projectService.getTotal(params).subscribe(
      res => {
        this.projectSummary = res.items?.[0];
        this.isProjectBillable = this.projectSummary?.isBillable;
      },
      err => {
        console.error(err);
      },
    );
  }

  billableFilterselectionChange(event): void {
    this.selectedBillableFilterValue = event.value;
    this.selectedBillableFilterText = event.text;
  }

  onProjectSelectionChange(selectedProject: any[]): void {
    this.selectedProjectIds = selectedProject.map(project => project.id);
  }

  onUserSelectionChange(selectedUsers: any[]): void {
    this.selectedUserIds = selectedUsers.map(user => user.id);
  }

  getPeopleList(): void {
    const param = {
      sorting: 'name asc',
      skipCount: 0,
      maxResultCount: 500,
    };

    this.service.getList(param).subscribe(response => {
      if (response.items.length > 0) {
        this.userList = response.items.map(user => {
          const fullName =
            user.name?.trim() && user.surname?.trim()
              ? `${user.name} ${user.surname}`
              : user.userName;

          return {
            ...user,
            userName: fullName,
          };
        });
        this.userListItems = this.userList.map(user => ({
          userName: user.userName,
          id: user.id,
        }));
        this.setLoggedUser();
      } else {
        this.userList = [];
      }
    });
  }

  setLoggedUser(): void {
    if (!this.selectedUserIds.includes(this.currentUser.id)) {
      this.selectedUserIds.push(this.currentUser.id);
    }
    const currentUserObject = this.userList.find(user => user.id === this.currentUser.id);
    if (currentUserObject !== null && currentUserObject !== undefined) {
      this.selectedUsers = [currentUserObject];
    }
  }

  exportTimesheet(format: ExportFormat): void {
    this.spinnerService.show();
    const params: TimelogGetListInput = {
      skipCount: this.skip,
      maxResultCount: this.pageSize,
      sorting: 'TimelogDatetime desc',
      startDate:
        this.selectedFilterValue == 1
          ? new Date(this.datePipe.transform(this.startDate, 'yyyy-MM-dd'))
              .toLocalISOString()
              .slice(0, 10)
          : undefined,
      endDate:
        this.selectedFilterValue == 1
          ? new Date(this.datePipe.transform(this.endDate, 'yyyy-MM-dd'))
              .toLocalISOString()
              .slice(0, 10)
          : undefined,
      format: format,
      isPagination: false,
      userIds: this.selectedUserIds,
      billable: this.selectedBillableFilterValue,
      projectIds: this.projectId ? [this.projectId] : this.selectedProjectIds?.map(id => +id),
    };

    this.commonService.exportTimesheet(params).subscribe(
      response => {
        saveAs(response.blob, response.filename.replace(/^"|"$/g, ''));

        this.spinnerService.hide();
        this.toasterService.success(
          NotificationMessage.exportSuccessMsg,
          '',
          this.commonService.toasterMessageConfiguration,
        );
      },
      error => {
        console.error('Download failed:', error);
        this.spinnerService.hide();
      },
    );
  }

  onEdit(data): void {
    const dialogRef = this.dialogService.open({
      content: TimeLogPopupComponent,
      width: 500,
      cssClass: 'add-time-log-quickly',
    });

    dialogRef.content.instance.timeLogId = data.id;
    dialogRef.content.instance.taskName = data.taskName;
    dialogRef.content.instance.taskId = this.taskId ?? data.taskId;
    dialogRef.content.instance.projectId = this.projectId ?? data.projectId;
    dialogRef.content.instance.isProjectBillable = this.isTimePage
      ? data.projectIsBillable
      : this.isProjectBillable;
    dialogRef.content.instance.userId = data.userId;

    dialogRef.result.subscribe((res: any) => {
      if (res && res.confirmed) {
        //this.getTimelogList();
        this.store.dispatch(new GetTimeLogList(this.getParamter())).subscribe();
      }
    });
  }

  calculateEndTime(timelogDatetime: string, timelogMinutes: number): Date {
    const startTime = new Date(timelogDatetime);
    startTime.setMinutes(startTime.getMinutes() + timelogMinutes);
    return startTime;
  }

  updateStartDate(event): void {
    this.startDate = event;
    this.validateDates();
    this.updateFormattedDateRange();
  }

  updateEndDate(event): void {
    this.endDate = event;
    this.validateDates();
    this.updateFormattedDateRange();
  }

  updateFormattedDateRange(): void {
    if (this.startDate && this.endDate) {
      this.formattedDateRange = `${formatDate(this.startDate, 'd MMM, y', 'en-US')} - ${formatDate(
        this.endDate,
        'd MMM, y',
        'en-US',
      )}`;
    }
  }

  validateDates() {
    if (this.startDate && this.endDate) {
      this.dateError = this.endDate < this.startDate;
    } else {
      this.dateError = false;
    }
  }

  closeDrawer() {
    this.isDrawerOpened = false;
    document.body.style.overflow = 'auto';
  }

  selectionChange(event): void {
    this.dateError = false;
    this.selectedFilterValue = event.value;
    if (this.selectedFilterValue == 0) {
      this.startDate = undefined;
      this.endDate = undefined;
      this.showFilterDate = false;
    } else {
      this.startDate = new Date();
      this.endDate = new Date();
      this.showFilterDate = true;
    }
  }

  openFilter(): void {
    this.isDrawerOpened = !this.isDrawerOpened;
    this.projectId = this.projectId;
    document.body.style.overflow = 'hidden';
  }

  pageChange(event: { skip: number; take: number }): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.getTimelogList(); // Reload with new pagination parameters
  }

  clearFilter(isDrawerClose: boolean = false): void {
    this.startDate = undefined;
    this.endDate = undefined;
    this.selectedFilterValue = 1;
    this.selectedBillableFilterValue = 0;
    this.selectedUsers = [];
    this.selectedUserIds = [];
    this.selectedProject = [];
    this.selectedProjectIds = [];
    this.startDate = new Date();
    this.endDate = new Date();
    this.gridView = {
      data: [],
      total: 0,
    };
    this.updateFilterCount();
    this.setLoggedUser();
    this.getData();
    if (isDrawerClose) this.closeDrawer();
  }

  getParamter(): TimelogGetListInput {
    const params: TimelogGetListInput = {
      userId: null,
      taskId: this.taskId,
      projectId: null,
      timelogDatetime: null,
      timelogMinutes: null,
      timelogDescription: null,
      timelogIsBillable: null,
      timelogHasStartTime: null,
      taskIdPreMove: null,
      deskTicketId: null,
      timelogInvoiceNumber: null,
      projectBillingInvoiceId: null,
      timelogFinalCost: null,
      maxResultCount: this.pageSize,
      skipCount: this.isDrawerOpened ? 0 : this.skip,
      sorting: 'TimelogDatetime desc',
      format: ExportFormat.Excel,
      isPagination: true,
      userIds: this.allowGrouping === false ? null : this.selectedUserIds,
      billable: this.selectedBillableFilterValue,
      projectIds: this.projectId ? [this.projectId] : this.selectedProjectIds?.map(id => +id),
      startDate:
        this.allowGrouping === false
          ? null
          : this.selectedFilterValue === 1
            ? new Date(this.datePipe.transform(this.startDate, 'yyyy-MM-dd'))
                .toLocalISOString()
                .slice(0, 10)
            : undefined,
      endDate:
        this.allowGrouping === false
          ? null
          : this.selectedFilterValue === 1
            ? new Date(this.datePipe.transform(this.endDate, 'yyyy-MM-dd'))
                .toLocalISOString()
                .slice(0, 10)
            : undefined,
    };

    return params;
  }

  getTimelogList(): void {
    this.store
      .dispatch(new GetTimeLogList(this.getParamter(), this.allowGrouping))
      .subscribe(res => {
        this.gridView = {
          data: res['task'].timeLogList,
          total: res['task'].timelogTotalCount,
        };

        this.isBillableColumnVisible = this.allowGrouping
          ? res['task'].timeLogList.some(group => group.items.some(item => !item.isBillable))
          : res['task'].timeLogList.every(item => !item.isBillable);
      });
  }

  updateFilterCount(): void {
    let count = 0;
    if (this.selectedFilterValue == 1) count++;
    if (this.selectedUserIds.length) count++;
    if (this.selectedProjectIds.length) count++;
    if (this.showBillableFilter) count++;
    this.filterCount = count;
  }

  getQueryParams(data: any) {
    return {
      taskId: btoa(data.taskId),
      projectId: btoa(this.projectId ?? data.projectId),
      projectName: btoa(data.projectName),
      taskListId: btoa(data.tasklistId),
    };
  }

  onGoToTaskDetailsClick(data, event: MouseEvent): void {
    this.openInNewTab = event.ctrlKey || event.metaKey;

    if (this.openInNewTab) return;

    event.preventDefault();

    if (!this.openInNewTab) {
      const params: { [key: string]: string } = {
        taskId: btoa(data.taskId),
        projectId: btoa(this.projectId ?? data.projectId),
        projectName: btoa(data.projectName),
        taskListId: btoa(data.tasklistId),
      };

      if (this.showProjectSummary) {
        params.tabId = btoa(TaskListTabEnum.Time.toString());
      }

      this.router.navigate(['task-Detail'], { queryParams: params });
    }
  }

  onRemoveFilterClick(filterType: number, selectedProjectOrUserId?: number): void {
    const isFilterCleared = () =>
      this.formattedDateRange === '' &&
      this.selectedUsers.length === 0 &&
      this.selectedProject.length === 0;

    const updateFilterCount = () => {
      if (isFilterCleared()) {
        this.filterCount = Math.max(this.filterCount - 1, 0);
      }
    };

    const updateShowBillableFilter = () => {
      this.showBillableFilter = !isFilterCleared();
    };

    switch (filterType) {
      case TimeComponentFilterType.DateFilter:
        this.startDate = new Date();
        this.endDate = new Date();
        this.selectedFilterValue = 0;
        this.showFilterDate = false;
        this.filterCount = Math.max(this.filterCount - 1, 0);
        updateShowBillableFilter();
        break;

      case TimeComponentFilterType.SelectedUsersFilter:
        this.selectedUserIds = this.selectedUserIds.filter(
          (id: any) => id !== selectedProjectOrUserId,
        );
        this.selectedUsers = this.selectedUsers.filter(user => user.id !== selectedProjectOrUserId);
        updateFilterCount();
        updateShowBillableFilter();
        break;

      case TimeComponentFilterType.SelectedProjectsFilter:
        this.selectedProjectIds = this.selectedProjectIds.filter(
          (id: any) => id !== selectedProjectOrUserId,
        );
        this.selectedProject = this.selectedProject.filter(
          project => project.id !== selectedProjectOrUserId,
        );
        updateFilterCount();
        updateShowBillableFilter();
        break;
    }

    const noDataToDisplay =
      !this.showFilterDate &&
      !this.showBillableFilter &&
      this.selectedProjectIds.length === 0 &&
      this.selectedUserIds.length === 0;

    if (noDataToDisplay) {
      this.gridView.data = [];
      this.filterCount = 0;
    } else {
      this.getTimelogList();
    }
  }

  openTimeLog(taskName) {
    const dialogRef = this.dialogService.open({
      content: TimeLogPopupComponent,
      width: 500,
      cssClass: 'add-time-log-quickly',
    });

    dialogRef.content.instance.startTime = new Date();
    dialogRef.content.instance.endTime = new Date();
    dialogRef.content.instance.taskId = this.taskId;
    dialogRef.content.instance.taskName = taskName;
    dialogRef.content.instance.projectId = this.projectId;
    dialogRef.content.instance.isProjectBillable = this.isProjectBillable;

    dialogRef.result.subscribe((res: any) => {
      if (res && res.confirmed) {
        this.store.dispatch(new AddTimelogList(res.data)).subscribe();
      }
    });
  }

  onDelete(data: any): void {
    const timelogDescription = data.timelogDescription ? data.timelogDescription : 'timelog';
    swal({
      title: NotificationTextMessage.areYouSureMessage,
      text: NotificationTextMessage.deleteMessageHeader + `${timelogDescription}` + ' ?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then(confirmed => {
      if (confirmed) {
        this.timelogService.delete(data.id).subscribe(
          res => {
            this.toasterService.success(
              NotificationMessage.deleteTimelogtSuccessMsg,
              '',
              this.commonService.toasterMessageConfiguration,
            );
            this.store.dispatch(new DeleteTimelogList(data, this.allowGrouping));
          },
          error => {
            const errorMessage = error.error.error.message || 'Delete Failed';
            this.toasterService.error(
              errorMessage,
              '',
              this.commonService.toasterMessageConfiguration,
            );
          },
        );
      }
    });
  }

  onProjectSearch(searchTerm: string): void {
    const contains = (value: string) => (item: { name: string; id: number }) =>
      item.name.toLowerCase().includes(value.toLowerCase());
    this.projectList = this.projectListItems.filter(contains(searchTerm));
  }

  onUserSearch(searchTerm: string): void {
    const contains = (value: string) => (item: { userName: string; id: number }) =>
      item.userName.toLowerCase().includes(value.toLowerCase());
    this.userList = this.userListItems.filter(contains(searchTerm));
  }
}
