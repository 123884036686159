<div *ngIf="(fileList$ | async).length === 0">
  <p class="font-size-12">
    No files are attached to this task -
    <a
      class="font-size-12"
      href="javascript:void(0)"
      (click)="openAttachedFile()"
      class="text-primary"
      >Attach files to this task</a
    >
  </p>
</div>

<div *ngIf="(fileList$ | async).length > 0" class="file-upload-container">
  <div
    class="d-flex justify-content-between gap-10 mb-10 flex-wrap mr-10"
    *ngFor="let file of fileList$ | async; let i = index"
  >
    <div class="d-flex justify-content-between gap-10">
      <div>
        <div
          class="file-icon-image-type"
          *ngIf="commonService.isFileTypeImage(file.fileName); else showInitialLogo"
        >
          <img [src]="file.previewImage" alt="" (click)="onImageOpen(i)" />
        </div>
        <ng-template #showInitialLogo>
          <div class="file-icon">
            <kendo-avatar
              rounded="full"
              size="large"
              [border]="true"
              [initials]="commonService.getInitials(file.fileName)"
              class="social-profile-text task-detail-avatar"
            ></kendo-avatar>
          </div>
        </ng-template>
      </div>

      <div class="file-name d-flex justify-content-between align-items-center">
        <div class="d-flex gap-5 flex-column">
          <span title="{{ file.fileName }}"> {{ file.fileName }} </span>
          <span class="file-details">by {{ file.creatorName }} - {{ file.fileSize }} kb</span>
        </div>
      </div>
    </div>
    <div class="d-flex gap-10">
      <div *ngIf="commonService.isFileTypeImage(file.fileName)">
        <span class="downloadFile-icon-container" (click)="onImageOpen(i)">
          <i class="far fa-eye k-icon font-size-14"></i>
        </span>
      </div>
      <div class="downloadFile-icon-container" (click)="downloadFile(file)">
        <i class="far fa-cloud-download-alt k-icon font-size-14"></i>
      </div>
      <div class="downloadFile-icon-container" (click)="onFileDelete(file)">
        <i class="far fa-trash-alt"></i>
      </div>
    </div>
  </div>
</div>

<p class="mb-0 mt-1" *ngIf="(fileList$ | async).length > 0">
  <a class="text-primary" href="javascript:void(0)" (click)="openAttachedFile()"
    >Manage task attachment</a
  >
</p>
