import {
  ListService,
  ConfigStateService,
  PermissionService,
  ABP,
  getShortDateFormat,
  getShortTimeFormat,
  getShortDateShortTimeFormat,
} from '@abp/ng.core';
import {
  EntityPropTypeClass,
  EntityPropList,
  EntityActionList,
  EntityProp,
  ENTITY_PROP_TYPE_CLASSES,
  ExtensionsService,
  EXTENSIONS_IDENTIFIER,
  PropData,
  ePropType,
  PROP_DATA_STREAM,
} from '@abp/ng.theme.shared/extensions';
import { formatDate } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  InjectFlags,
  InjectionToken,
  Injector,
  Input,
  LOCALE_ID,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  TrackByFunction,
  Type,
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable, map } from 'rxjs';
import { CommonService } from 'src/core/services';

const DEFAULT_ACTIONS_COLUMN_WIDTH = 150;

@Component({
  selector: 'app-custom-extensible-table',
  templateUrl: './custom-extensible-table.component.html',
  styleUrls: ['./custom-extensible-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomExtensibleTableComponent<R = any> implements OnInit, OnChanges {
  gridHeight: number;
  datatableBodyClass: string = 'custom-datatable-body';
  protected _actionsText!: string;
  @Input()
  set actionsText(value: string) {
    this._actionsText = value;
  }
  get actionsText(): string {
    return this._actionsText ?? (this.actionList.length > 1 ? 'AbpUi::Actions' : '');
  }

  @Input() data!: R[];
  @Input() list!: ListService;
  @Input() recordsTotal!: number;
  @Input() set actionsColumnWidth(width: number) {
    this.setColumnWidths(width ? Number(width) : undefined);
  }
  @Input() actionsTemplate?: TemplateRef<any>;

  @Output() tableActivate = new EventEmitter();

  getInjected: <T>(token: Type<T> | InjectionToken<T>, notFoundValue?: T, flags?: InjectFlags) => T;

  hasAtLeastOnePermittedAction: boolean;

  entityPropTypeClasses: EntityPropTypeClass;

  readonly columnWidths!: number[];

  readonly propList: EntityPropList<R>;

  readonly actionList: EntityActionList<R>;

  readonly trackByFn: TrackByFunction<EntityProp<R>> = (_, item) => item.name;
  permissiontxt = 'Permission';
  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private config: ConfigStateService,
    private injector: Injector,
    private commonService: CommonService,
    private router: Router,
  ) {
    if (this.router.url === '/tenant-management/tenants') {
      this.permissiontxt = 'Features';
    }

    this.entityPropTypeClasses = injector.get(ENTITY_PROP_TYPE_CLASSES);
    this.getInjected = injector.get.bind(injector);
    const extensions = injector.get(ExtensionsService);
    const name = injector.get(EXTENSIONS_IDENTIFIER);
    this.propList = extensions.entityProps.get(name).props;
    this.actionList = extensions['entityActions'].get(name)
      .actions as unknown as EntityActionList<R>;

    const permissionService = injector.get(PermissionService);
    this.hasAtLeastOnePermittedAction =
      permissionService.filterItemsByPolicy(
        this.actionList.toArray().map(action => ({ requiredPolicy: action.permission })),
      ).length > 0;
    this.setColumnWidths(DEFAULT_ACTIONS_COLUMN_WIDTH);
  }

  ngOnInit(): void {
    this.calculateGridHeight();
    window.addEventListener('resize', () => {});
  }

  private setColumnWidths(actionsColumn: number | undefined): void {
    const widths = [actionsColumn];
    this.propList.forEach(({ value: prop }) => {
      widths.push(prop.columnWidth);
    });
    (this.columnWidths as any) = widths;
  }

  private getDate(value: Date | undefined, format: string | undefined): string {
    return value && format ? formatDate(value, format, this.locale) : '';
  }

  private getIcon(value: boolean): string {
    return value
      ? '<div class="text-success"><i class="fa fa-check" aria-hidden="true"></i></div>'
      : '<div class="text-danger"><i class="fa fa-times" aria-hidden="true"></i></div>';
  }

  private getEnum(rowValue: any, list: Array<ABP.Option<any>>): string {
    if (!list || list.length < 1) return rowValue;
    const { key } = list.find(({ value }) => value === rowValue) || {};
    return key;
  }

  getContent(prop: EntityProp<R>, data: PropData): Observable<string> {
    return prop.valueResolver(data).pipe(
      map(value => {
        switch (prop.type) {
          case ePropType.Boolean:
            return this.getIcon(value);
          case ePropType.Date:
            return this.getDate(value, getShortDateFormat(this.config));
          case ePropType.Time:
            return this.getDate(value, getShortTimeFormat(this.config));
          case ePropType.DateTime:
            return this.getDate(value, getShortDateShortTimeFormat(this.config));
          case ePropType.Enum:
            return this.getEnum(value, prop.enumList || []);
          default:
            return value;
        }
      }),
    );
  }

  ngOnChanges({ data }: SimpleChanges): void {
    if (!data?.currentValue) return;

    this.data = data.currentValue.map((record: any, index: number) => {
      this.propList.forEach(prop => {
        const propData = { getInjected: this.getInjected, record, index } as any;
        const value = this.getContent(prop.value, propData);

        const propKey = `_${prop.value.name}`;
        record[propKey] = {
          visible: prop.value.visible(propData),
          value,
        };
        if (prop.value.component) {
          const injector = Injector.create({
            providers: [
              {
                provide: PROP_DATA_STREAM,
                useValue: value,
              },
            ],
            parent: this.injector,
          });
          record[propKey].injector = injector;
          record[propKey].component = prop.value.component;
        }
      });

      return record;
    });
  }

  onEditClick(data): void {
    this.commonService.editChange.next(data);
  }

  onPermissionClick(data): void {
    this.commonService.permissionChange.next(data);
  }

  onDeleteClick(data): void {
    this.commonService.deleteChange.next(data);
  }

  calculateGridHeight(): string {
    const screenHeight = window.innerHeight;
    const headerHeight = 64;
    const gridHeaderHeight = 69;
    const wrapperpadding = 60;

    this.gridHeight = screenHeight - (headerHeight + gridHeaderHeight + wrapperpadding);
    return '${this.gridHeight}px';
  }
}
