import { Component, OnInit, ViewChild } from '@angular/core';
import { SortDescriptor, orderBy, process } from '@progress/kendo-data-query';
import {
  DataBindingDirective,
  GridComponent,
  GridDataResult,
  PageChangeEvent,
  PagerPosition,
  PagerType,
} from '@progress/kendo-angular-grid';
import { SVGIcon, filterIcon } from '@progress/kendo-svg-icons';
import { DialogService } from '@progress/kendo-angular-dialog';
import { TagsListComponent } from 'projects/project/src/pages/project/tags-list/tags-list.component';
import { ListService, PagedResultDto } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { AddCompanyPopupComponent } from '../add-company-popup/add-company-popup.component';
import { StatusTypeEnum } from 'src/app/enum/status-type-enum';
import { NotificationMessage, NotificationTextMessage } from 'src/app/enum/notification';
import { CommonService } from 'src/core/services';
import swal from 'sweetalert/dist/sweetalert.min.js';
import { NgxSpinnerService } from 'ngx-spinner';
import { CompanyService } from 'projects/project-service/src/lib/proxy/project-service';

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss'],
})
export class CompanyListComponent implements OnInit {
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  @ViewChild(GridComponent) grid!: GridComponent;

  public isAddCompany = false;
  public opened = false;
  isGridGrouped: boolean = false;
  public mySelection: string[] = [];
  public filterIcon: SVGIcon = filterIcon;
  public groups: any[] = [{ field: 'CompanyName' }];
  searchText = '';
  gridHeight: number;
  public selectedFilterValue = 1;
  public statusFilter: Array<{ text: string; value: number }> = [{ text: 'All Status', value: 1 }];
  statusType = StatusTypeEnum;
  //#region  Pagination
  public type: PagerType = 'numeric';
  public buttonCount = 5;
  public info = true;
  public pageSizes = true;
  public previousNext = true;
  public pagerposition: PagerPosition = 'bottom';
  public pageSize = 10;
  public skip = 0;
  public sorting = null;
  public multiple = false;
  public allowUnsort = true;
  isNoRecords = false;

  companyData: GridDataResult;
  public sort: SortDescriptor[] = [
    {
      field: 'name',
    },
    {
      field: 'website',
    },
    {
      field: 'email1',
    },
    {
      field: 'phone',
    },
  ];
  constructor(
    private dialogService: DialogService,
    private companyService: CompanyService,
    public readonly list: ListService,
    private toasterService: ToasterService,
    public commonService: CommonService,
    private spinnerService: NgxSpinnerService,
  ) {}

  public ngOnInit(): void {
    this.getCompanyList();

    this.calculateGridHeight();

    window.addEventListener('resize', () => {
      this.calculateGridHeight();
    });
  }

  calculateGridHeight(): void {
    const screenHeight = window.innerHeight;
    const headerHeight = 64;
    const gridHeaderHeight = 69;
    const wrapperpadding = 60;

    this.gridHeight = screenHeight - (headerHeight + gridHeaderHeight + wrapperpadding);
  }

  getCompanyList(): void {
    this.spinnerService.show();
    const param: any = {
      sorting: this.sorting,
      skipCount: this.skip,
      maxResultCount: this.pageSize,
      searchTerm: this.searchText,
    };

    this.companyService.getList(param).subscribe((res: any) => {
      this.spinnerService.hide();
      this.companyData = {
        data: orderBy(res.items, this.sort),
        total: res.totalCount,
      };
      this.isNoRecords = res.items.length > 0 ? false : true;
    });
  }

  public open(): void {
    this.opened = true;
  }
  public close(): void {
    this.opened = false;
  }
  public groupBy() {
    this.isGridGrouped = !this.isGridGrouped;
    this.grid.groupable = this.isGridGrouped;
    this.groups = this.isGridGrouped ? [{ field: 'CompanyName' }] : [];
    this.groupChange(this.groups);
  }

  public groupChange(groups: any[]): void {
    this.groups = groups;
  }

  removeTag(data: any, i: number): void {
    data.splice(i, 1);
  }
  tagsList: any[] = [
    { id: 1, name: 'tags 1' },
    { id: 2, name: 'tags 2' },
    { id: 3, name: 'tags 3' },
    { id: 4, name: 'tags 4' },
  ];

  openTags(list: any): void {
    const dialogRef = this.dialogService.open({
      content: TagsListComponent,
      width: 300,
    });

    const tagInfo = dialogRef.content.instance as TagsListComponent;
    tagInfo.tagsList = this.tagsList;
    tagInfo.selectedtagsList = list.length > 0 ? list : [];

    dialogRef.result.subscribe(res => {
      if (res) {
        const newObj: any = this.tagsList.filter(x => x.name === tagInfo.formGroup.value.tagName);
        list.push(newObj[0]);
      }
    });
  }

  delete(company: any): void {
    if (company.id) {
      this.companyService.delete(company.id).subscribe(res => {
        this.toasterService.success('Delete Succesfully', '', {});
        this.getCompanyList();
      });
    }
  }

  selectionChange(event): void {}

  onAddCompany(id?: any): void {
    const dialogRef = this.dialogService.open({
      content: AddCompanyPopupComponent,
      width: 450,
    });
    const attachFileInfo = dialogRef.content.instance as AddCompanyPopupComponent;
    dialogRef.content.instance.companyId = id;
    attachFileInfo.setOnLoadData();
    dialogRef.content.instance.companyList = this.companyData.data;

    dialogRef.result.subscribe((res: any) => {
      if (res && res.confirmed) {
        this.getCompanyList();
      }
    });
  }

  public pageChange({ skip, take }: PageChangeEvent): void {
    this.skip = skip;
    this.pageSize = take;
    this.searchText = '';
    this.getCompanyList();
  }

  sortChange(sort): void {
    this.sort = sort;
    this.sorting = sort[0].field + ' ' + (sort[0].dir ?? '');
    this.getCompanyList();
  }

  onSearch(event: any): void {
    this.getCompanyList();
  }

  onSearchTextChange(searchText: string): void {
    if (searchText.length > 2 || searchText.length === 0) {
      this.searchText = '';
      this.getCompanyList();
    }
  }

  onDelete(companyData) {
    swal({
      title: NotificationTextMessage.areYouSureMessage,
      text: NotificationTextMessage.deleteMessageHeader + companyData.name + ' ?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then(confirmed => {
      if (confirmed) {
        this.companyService.delete(companyData.id).subscribe(
          res => {
            this.toasterService.success(
              NotificationMessage.deleteCompanySuccessMsg,
              '',
              this.commonService.toasterMessageConfiguration,
            );
            this.getCompanyList();
          },
          error => {
            const errorMessage = error.error.error.message || 'Delete Failed';
            this.toasterService.error(
              errorMessage,
              '',
              this.commonService.toasterMessageConfiguration,
            );
          },
        );
      }
    });
  }
}
