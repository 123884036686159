import { DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { DashboardService } from 'projects/project-service/src/lib/proxy/task-service/dashboard';
import { TaskStatusType } from 'projects/task-service/src/lib/proxy/task-service';
import { NotificationTextMessage } from 'src/app/enum/notification';
import { CommonService } from 'src/core/services';
import { Priority } from 'tasks/task/config/src/enums/priority';
import { TaskService } from 'projects/task-service/src/lib/proxy/task-service/task/task.service';
import { ToasterService } from '@abp/ng.theme.shared';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-count-popup',
  templateUrl: './dashboard-count-popup.component.html',
  styleUrls: ['./dashboard-count-popup.component.scss'],
})
export class DashboardCountPopupComponent {
  headerText: string;
  cardType: any;
  gridData: any[] = [];
  taskStatusType = TaskStatusType;

  isNoRecord = false;
  openInNewTab = false;

  constructor(
    public dialogRef: DialogRef,
    private dashboardService: DashboardService,
    private spinnerService: NgxSpinnerService,
    private datePipe: DatePipe,
    public commonService: CommonService,
    private taskService: TaskService,
    private toasterService: ToasterService,
    private router: Router,
  ) {}

  onCloseDialog(): void {
    this.dialogRef.close();
  }

  checkIsDisable(dataItem: any): boolean {
    return !dataItem.isAllSubTaskCompleted;
  }

  onTaskCompleteChange(dataItem: any): void {
    const isTaskCompleted = dataItem.taskStatus === TaskStatusType.Completed;

    const action = isTaskCompleted
      ? this.taskService.markTaskAsReopenedById(dataItem.taskId)
      : this.taskService.markTaskAsCompleteById(dataItem.taskId);

    action.subscribe(res => {
      this.getTaskList();
      this.toasterService.success(
        NotificationTextMessage.taskUpdatedMessage,
        '',
        this.commonService.toasterMessageConfiguration,
      );
    });
  }

  getTaskTypeIconClass(data: number) {
    return data === 0
      ? 'far fa-clipboard-list font-size-14'
      : 'far fa-bug font-size-14 bug-icon-color';
  }

  getTaskList(): void {
    this.spinnerService.show();
    this.gridData = [];
    this.dashboardService.getDashboardTaskListByStatus(this.cardType).subscribe(res => {
      this.spinnerService.hide();
      res.forEach(x => {
        const data: any = {
          taskName: x.taskName,
          project: x.projectName,
          projectLogo: x.projectLogo,
          dueDate: this.datePipe.transform(x.taskDuedate, 'MMM d, y'),
          priority: Priority[x.taskPriority],
          taskId: x.taskId,
          taskListId: x.taskListId,
          taskType: x.taskType,
          taskStatus: x.taskStatus,
          hasChildren: false,
          isAllSubTaskCompleted: x.isAllSubTaskCompleted,
          taskListName: x.taskListName,
          isHeader: false,
          parentTaskId: x.parentTaskId,
          projectId: x.projectId,
        };
        this.gridData.push(data);
      });
      this.isNoRecord = this.gridData.length > 0 ? false : true;
    });
  }

  onTaskNameClick(data: any, event: MouseEvent): void {
    this.openInNewTab = event.ctrlKey || event.metaKey;

    if (this.openInNewTab) return;

    event.preventDefault();
    if (!this.openInNewTab) {
      var params = {
        taskId: btoa(data.taskId),
        projectId: btoa(data.projectId),
        projectName: btoa(data.project),
        taskListId: btoa(data.taskListId),
      };

      this.router.navigate(['task-Detail'], { queryParams: params });
    }
  }

  getQueryParams(data: any) {
    return {
      taskId: btoa(data.taskId),
      projectId: btoa(data.projectId),
      projectName: btoa(data.project),
      taskListId: btoa(data.taskListId),
    };
  }
}
