import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import {
  AddSubTask,
  AddTimelogList,
  DeleteSubTask,
  DeleteTimelogList,
  GetFileList,
  GetList,
  GetSubTaskList,
  GetTimeLogList,
  SetTaskStatus,
  MarkTaskAsCompletedSubTask,
  UpdateSubTask,
  GetCommentList,
  SetTimelogList,
} from './task.action';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { TaskService } from 'projects/task-service/src/lib/proxy/task-service/task/task.service';
import { FileService } from 'projects/task-service/src/lib/proxy/service/task/file.service';
import { TimelogService } from 'projects/task-service/src/lib/proxy/task-service/timelog';
import { CommentService } from 'projects/comment-service/src/lib/proxy/comment-service';
import { process } from '@progress/kendo-data-query';
import { DatePipe } from '@angular/common';
export class TaskStateInfo {
  taskList?: any;
  subTaskList?: any;
  fileList?: any;
  timeLogList: any;
  totalLoggedTime: any;
  totalLoggedBillableTime: any;
  totalLoggedNonBillableTime: any;
  totalLoggedEstimate: any;
  totalLoggedNotBilled: any;
  taskStatus?: any;
  commentList?: any;
  commentCount: number;
  timelogTotalCount?: number;
  taskStatusText?: any;
}

@State<TaskStateInfo>({
  name: 'task',
  defaults: {
    taskList: [],
    subTaskList: [],
    fileList: [],
    timeLogList: [],
    commentList: [],
    totalLoggedTime: '',
    totalLoggedBillableTime: '',
    totalLoggedNonBillableTime: '',
    totalLoggedEstimate: '',
    totalLoggedNotBilled: '',
    taskStatus: '',
    commentCount: 0,
    taskStatusText: '',
  },
})
@Injectable()
export class TaskState {
  constructor(
    private taskService: TaskService,
    private fileService: FileService,
    private timelogService: TimelogService,
    private commentService: CommentService,
    private datePipe: DatePipe,
  ) {}
  @Selector()
  static getList(state: TaskStateInfo) {
    return state.taskList;
  }

  @Selector()
  static getSubTaskList(state: TaskStateInfo) {
    return state.subTaskList;
  }

  @Selector()
  static getFileList(state: TaskStateInfo) {
    return state.fileList;
  }

  @Selector()
  static getTimelogList(state: TaskStateInfo) {
    return state.timeLogList;
  }

  @Selector()
  static getCommentList(state: TaskStateInfo) {
    return state.commentList;
  }

  @Selector()
  static getTotalLoggedEstimate(state: TaskStateInfo) {
    return state.totalLoggedEstimate;
  }

  @Selector()
  static getTotalLoggedTime(state: TaskStateInfo) {
    return state.totalLoggedTime;
  }

  @Selector()
  static getTimelogCount(state: TaskStateInfo) {
    return state.timelogTotalCount;
  }

  @Selector()
  static getTotalLoggedNotBilled(state: TaskStateInfo) {
    return state.totalLoggedNotBilled;
  }

  @Selector()
  static getTotalLoggedBillableTime(state: TaskStateInfo) {
    return state.totalLoggedBillableTime;
  }

  @Selector()
  static getTotalLoggedNonBillableTime(state: TaskStateInfo) {
    return state.totalLoggedNonBillableTime;
  }

  @Selector()
  static getTaskStatus(state: TaskStateInfo) {
    return state.taskStatus;
  }

  @Action(GetList)
  getList({ getState, setState }: StateContext<TaskStateInfo>, action: any) {
    return this.taskService.getList(action.queryParams).pipe(
      tap(res => {
        const state = getState();
        setState({
          ...state,
          taskList: res,
        });
      }),
    );
  }

  @Action(GetSubTaskList)
  getSubTaskList({ getState, setState }: StateContext<TaskStateInfo>, action: any) {
    return this.taskService
      .getSubTasksByParentTaskIdAndPagedAndSortedResultRequest(action.taskId, action.param)
      .pipe(
        tap(res => {
          const state = getState();

          setState({
            ...state,
            subTaskList: res.items,
          });
        }),
      );
  }

  @Action(AddSubTask)
  addSubTask({ getState, patchState }: StateContext<TaskStateInfo>, { subTask }: AddSubTask) {
    const state = getState();

    patchState({
      subTaskList: [...state.subTaskList, subTask],
    });
  }

  @Action(AddTimelogList)
  addTimelogList(
    { getState, patchState }: StateContext<TaskStateInfo>,
    { addTimelog }: AddTimelogList,
  ) {
    const state = getState();
    if (
      state.timeLogList.length > 0 &&
      !state.timeLogList.some(e => e.taskId === addTimelog.taskId)
    ) {
      return;
    }

    addTimelog.canEditOrDelete = true;

    addTimelog.timelogDatetime = addTimelog.timelogDatetime.replace('Z', '');

    addTimelog.timelogUserName =
      addTimelog.timelogUserFirstName?.trim() && addTimelog.timelogUserLastName?.trim()
        ? `${addTimelog.timelogUserFirstName} ${addTimelog.timelogUserLastName}`
        : addTimelog.timelogUserName;

    const timeLogList = {
      ...addTimelog,
      hours: this.removeTrailingZeros((addTimelog.timelogMinutes / 60).toFixed(3)),
    };
    const updatedTimeLogList = [...state.timeLogList, timeLogList];

    const timelogTotalCount =
      updatedTimeLogList.length > timeLogList.length
        ? state.timelogTotalCount + 1
        : state.timelogTotalCount;

    const timelogBillableMinutes = addTimelog.timelogBillableMinutes ?? 0;
    
    patchState({
      timeLogList: updatedTimeLogList,
      totalLoggedTime: +state.totalLoggedTime + addTimelog.timelogMinutes,
      totalLoggedBillableTime: state.totalLoggedBillableTime + timelogBillableMinutes,
      totalLoggedNonBillableTime:
        state.totalLoggedNonBillableTime + addTimelog.timelogMinutes - timelogBillableMinutes,
      timelogTotalCount: timelogTotalCount,
      totalLoggedEstimate: state.totalLoggedEstimate + addTimelog.taskEstimateMinutes,
      totalLoggedNotBilled: state.totalLoggedNotBilled,
    });
  }

  @Action(UpdateSubTask)
  updateSubTask(
    { getState, patchState }: StateContext<TaskStateInfo>,
    { index, updatedSubTask }: UpdateSubTask,
  ) {
    const state = getState();
    const subTaskList = [...state.subTaskList];
    subTaskList[index] = updatedSubTask; // Update the subtask at the specified index
    patchState({
      subTaskList: subTaskList,
    });
  }

  @Action(DeleteSubTask)
  deleteSubTask(
    { getState, patchState }: StateContext<TaskStateInfo>,
    { subTaskId }: DeleteSubTask,
  ) {
    const state = getState();
    const updatedSubTaskList = state.subTaskList.filter(subtask => subtask.id !== subTaskId);

    patchState({
      subTaskList: updatedSubTaskList,
    });
  }

  @Action(MarkTaskAsCompletedSubTask)
  markTaskAsCompletedSubTask(
    { getState, patchState }: StateContext<TaskStateInfo>,
    { taskId, taskStatus }: MarkTaskAsCompletedSubTask,
  ) {
    const state = getState();
    const updatedSubTaskList = state.subTaskList.map(subtask =>
      subtask.id === taskId ? { ...subtask, taskStatus: taskStatus } : subtask,
    );
    patchState({
      subTaskList: updatedSubTaskList,
    });
  }

  @Action(SetTaskStatus)
  setTaskStatus(
    { getState, patchState }: StateContext<TaskStateInfo>,
    { taskStatus }: SetTaskStatus,
  ) {
    patchState({
      taskStatus: taskStatus,
    });
  }

  @Action(GetFileList)
  getFileList({ getState, patchState }: StateContext<TaskStateInfo>, action: any) {
    return this.fileService.getList(action.taskId).pipe(
      tap(res => {
        const state = getState();

        let fileList = [];

        if (res.length > 0) {
          fileList = res.map(element => {
            const latestFileVersion = element.fileVersions.reduce((prev, current) =>
              prev.versionNo > current.versionNo ? prev : current,
            );
            return {
              ...latestFileVersion,
              previewImage: element.previewImage,
              src: element.previewImage,
            };
          });
        }

        patchState({
          ...state,
          fileList: fileList,
        });
      }),
    );
  }

  @Action(SetTimelogList)
  setTimelogList({ setState, getState }: StateContext<TaskStateInfo>) {
    const currentState = getState();
    setState({
      ...currentState,
      timeLogList: [],
    });
  }

  @Action(GetTimeLogList)
  getTimeLogList({ getState, patchState }: StateContext<TaskStateInfo>, action: GetTimeLogList) {
    return this.timelogService.getList(action.params).pipe(
      tap((res: any) => {
        const state = getState();
        let timeLogList = res.items.map((element: any) => {
          element.hours = this.removeTrailingZeros((element.timelogMinutes / 60).toFixed(3));
          return element;
        });

        timeLogList = res.items.map(item => {
          const fullName =
            item.timelogUserFirstName?.trim() && item.timelogUserLastName?.trim()
              ? `${item.timelogUserFirstName} ${item.timelogUserLastName}`
              : item.timelogUserName;

          return {
            ...item,
            timelogUserName: fullName,
            Date: this.datePipe.transform(
              new Date(item.timelogDatetime).toISOString().slice(0, 10),
              'EEEE dd, MMMM',
            ),
          };
        });

        if (action.allowGrouping) {
          const groupedData = process(timeLogList, {
            group: [
              {
                field: 'Date',
                aggregates: [
                  { field: 'timelogMinutes', aggregate: 'sum' },
                  { field: 'timelogBillableMinutes', aggregate: 'sum' },
                  { field: 'hoursDecimal', aggregate: 'sum' },
                ],
              },
            ],
          });

          groupedData.data = groupedData.data.sort(
            (a, b) => new Date(b.value).getTime() - new Date(a.value).getTime(),
          );

          timeLogList = groupedData.data;
        }

        patchState({
          ...state,
          timeLogList: timeLogList,
          timelogTotalCount: res.totalCount,
          totalLoggedTime: res.logged,
          totalLoggedNonBillableTime: res.nonBillable,
          totalLoggedEstimate: res.estimated,
          totalLoggedNotBilled: res.notBilled,
          totalLoggedBillableTime: res.billable,
        });
      }),
    );
  }

  @Action(DeleteTimelogList)
  deleteTimelogList(
    { getState, patchState }: StateContext<TaskStateInfo>,
    { timelogData, allowGrouping }: DeleteTimelogList,
  ) {
    const state = getState();

    const timeLogListToFilter = allowGrouping ? state.timeLogList[0].items : state.timeLogList;

    const updatedTimeLogList = timeLogListToFilter.filter(timelog => timelog.id !== timelogData.id);

    patchState({
      timeLogList: updatedTimeLogList,
      totalLoggedTime: state.totalLoggedTime - timelogData.timelogMinutes,
      totalLoggedBillableTime: state.totalLoggedBillableTime - timelogData.timelogBillableMinutes,
      totalLoggedNonBillableTime: state.totalLoggedNonBillableTime - timelogData.timelogMinutes,
      totalLoggedEstimate: state.totalLoggedEstimate - timelogData.taskEstimateMinutes,
      totalLoggedNotBilled: state.totalLoggedNotBilled,
      timelogTotalCount: state.timelogTotalCount - 1,
    });
  }

  @Action(GetCommentList)
  getCommentList({ getState, setState }: StateContext<TaskStateInfo>, action: GetCommentList) {
    return this.commentService.getList(action.params).pipe(
      tap(res => {
        const state = getState();
        setState({
          ...state,
          commentList: res.items,
          commentCount: res.totalCount,
        });
      }),
    );
  }

  removeTrailingZeros(str: string): string {
    return str.replace(/(\.0+|(?<=\..*?)0+)$/, '');
  }
}
