<kendo-tabstrip>
  <kendo-tabstrip-tab title="People" [selected]="true">
    <ng-template kendoTabContent>
      <p>
        <kendo-formfield>
          <kendo-label text="Select project owner" labelCssClass="k-form-label"></kendo-label>
          <kendo-dropdownlist #projectOwner [data]="projectOwnerList"> </kendo-dropdownlist>
        </kendo-formfield>

        <kendo-treelist
          [kendoTreeListFlatBinding]="peopleList.items"
          idField="id"
          parentIdField="managerId"
          [height]="410"
          kendoTreeListExpandable
          [initiallyExpanded]="true"
          kendoTreeListSelectable
          [selectable]="settings"
          [(selectedItems)]="selected"
          [columnMenu]="true"
          [sortable]="true"
          [resizable]="true"
          [reorderable]="true"
          [navigable]="true"
          [pageable]="true"
          [pageSize]="15"
          (selectionChange)="onSelectionChange($event)"
        >
          <kendo-treelist-checkbox-column
            [width]="40"
            [columnMenu]="false"
            [checkChildren]="true"
            [showSelectAll]="true"
          >
          </kendo-treelist-checkbox-column>
          <kendo-treelist-column [expandable]="true" field="name" title="PeopleList" [width]="350">
            <ng-template kendoTreeListCellTemplate let-dataItem>
              <div class="customer-name">{{ dataItem.name }}</div>
            </ng-template>
          </kendo-treelist-column>
        </kendo-treelist>
      </p>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title="Teams">
    <ng-template kendoTabContent>
      <p>Tab 2 Content</p>
    </ng-template>
  </kendo-tabstrip-tab>
  <!-- <kendo-tabstrip-tab title="Teams">
    <ng-template kendoTabContent>
      <p>
        <kendo-treelist
          [kendoTreeListFlatBinding]="teamList"
          idField="id"
          parentIdField="managerId"
          [height]="410"
          kendoTreeListExpandable
          [initiallyExpanded]="true"
          kendoTreeListSelectable
          [selectable]="settings"
          [(selectedItems)]="selected"
          [columnMenu]="true"
          [sortable]="true"
          [resizable]="true"
          [reorderable]="true"
          [navigable]="true"
          [pageable]="true"
          [pageSize]="15"
        >
          <kendo-treelist-checkbox-column
            [width]="40"
            [columnMenu]="false"
            [checkChildren]="true"
            [showSelectAll]="true"
          >
          </kendo-treelist-checkbox-column>
          <kendo-treelist-column [expandable]="true" field="name" title="Name" [width]="350">
            <ng-template kendoTreeListCellTemplate let-dataItem>
              <div class="customer-name">{{ dataItem.name }}</div>
            </ng-template>
          </kendo-treelist-column>
        </kendo-treelist>
      </p>
    </ng-template>
  </kendo-tabstrip-tab> -->
</kendo-tabstrip>
