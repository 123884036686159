import { ConfigStateService, ListService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DialogService } from '@progress/kendo-angular-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationMessage, NotificationTextMessage } from 'src/app/enum/notification';
import swal from 'sweetalert/dist/sweetalert.min.js';
import {
  GridDataResult,
  PageChangeEvent,
  PagerPosition,
  PagerType,
} from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { ProjectDto } from '@proxy/project-service/projects/dtos';
import { ProjectListType, ProjectStatusFilter } from 'src/app/enum/project-status-filter';
import { AddProjectComponent } from '../add-project/add-project.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/core/services';
import { LabelSettings } from '@progress/kendo-angular-progressbar';
import { CompanyDto, ProjectService } from 'projects/project-service/src/lib/proxy/project-service';
import { GetProjectListInputDto } from 'projects/project-service/src/lib/proxy/project-service/projects/dtos';

@Component({
  selector: 'lib-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss'],
})
export class ProjectListComponent implements OnInit {
  //#region  Pagination
  public gridView: GridDataResult;
  public type: PagerType = 'numeric';
  public buttonCount = 5;
  public info = true;
  public pageSizes = true;
  public previousNext = true;
  public pagerposition: PagerPosition = 'bottom';
  public pageSize = 10;
  public skip = 0;
  public sorting = 'name asc';
  public multiple = false;
  public allowUnsort = true;
  isNoRecords = false;
  titleName = '';
  //#endregion
  label: LabelSettings = {
    visible: true,
    format: 'percent',
    position: 'start',
  };

  statusFilter = ProjectStatusFilter;

  public sort: SortDescriptor[] = [
    {
      field: 'name',
    },
    {
      field: 'company',
    },
    {
      field: 'tasks',
    },
    {
      field: 'creationTime',
    },
    {
      field: 'endDate',
    },
    {
      field: 'status',
    },
  ];

  companiesList$: Observable<CompanyDto[]>;

  selectedStatus: number;
  searchText: string = '';
  gridHeight: number;
  isFromProduct: boolean;
  isFromPortfolio: boolean;
  productId: any;
  portfolioId: any;
  isAdmin: boolean = false;
  openInNewTab = false;

  constructor(
    public readonly list: ListService,
    private readonly projectService: ProjectService,
    private dialogService: DialogService,
    private router: Router,
    private toasterService: ToasterService,
    private spinnerService: NgxSpinnerService,
    public commonService: CommonService,
    private _Activatedroute: ActivatedRoute,
    private config: ConfigStateService,
  ) {
    this.list.maxResultCount = 100;
    this.companiesList$ = projectService.getCompaniesLookup().pipe(map(r => r.items));
    var currentUserRole: any[] = this.config.getOne('currentUser').roles;
    if (currentUserRole.includes('admin')) {
      this.isAdmin = true;
    }
  }

  ngOnInit(): void {
    this._Activatedroute.queryParams.subscribe(params => {
      this.isFromProduct = false;
      this.isFromPortfolio = false;

      if (params) {
        this.isFromProduct =
          params['isFromProduct'] !== null && params['isFromProduct'] !== undefined
            ? JSON.parse(atob(params['isFromProduct']))
            : false;
        this.productId = params['productId'] ? atob(params['productId']) : undefined;
        this.isFromPortfolio =
          params['isFromPortfolio'] !== null && params['isFromPortfolio'] !== undefined
            ? JSON.parse(atob(params['isFromPortfolio']))
            : false;

        this.portfolioId = params['portfolioId'] ? atob(params['portfolioId']) : undefined;

        this.titleName = '';
        if (this.isFromProduct) {
          this.titleName = atob(params['productName']);
        } else if (this.isFromPortfolio) {
          this.titleName = atob(params['portfolioName']);
        }
      }
    });

    this.selectedStatus = ProjectStatusFilter['All Status'];
    this.getList();
    this.calculateGridHeight();

    window.addEventListener('resize', () => {
      this.calculateGridHeight();
    });
  }

  onSearchKeyupEnter(): void {
    this.skip = 0;
    this.getList();
  }

  calculateGridHeight(): void {
    const screenHeight = window.innerHeight;
    const headerHeight = 64;
    const gridHeaderHeight = 69;
    const wrapperpadding = 60;

    this.gridHeight = screenHeight - (headerHeight + gridHeaderHeight + wrapperpadding);
  }

  statusChange(event: any): void {
    this.selectedStatus = event.key;
    this.getList();
  }

  onSearchTextChange(searchText: string): void {
    if (searchText.length > 2 || searchText.length === 0) {
      this.getList();
    }
  }

  getList(): void {
    const data: GetProjectListInputDto = {
      includeProjectUserInfo: true,
      include: [],
      fields: [],
      orderByCustomFieldId: 0,
      filter: '',
      searchCompanies: '',
      projectCompanyIds: '',
      onlyStarredProjects: true,
      hideObservedProjects: true,
      includeCounts: true,
      projectStatuses: true,
      iIncludeCustomFields: true,
      searchByLetter: true,
      sorting: this.sorting,
      skipCount: this.skip,
      maxResultCount: this.pageSize,
      searchTerm: this.searchText,
      statusSearchTerm: this.selectedStatus,
      projectIds: [],
    };

    this.spinnerService.show();
    let getApi: any;
    if (this.isFromProduct) {
      getApi = this.projectService.getListByProjectId(+this.productId, data);
    } else if (this.isFromPortfolio) {
      getApi = this.projectService.getListByPortfolioId(+this.portfolioId, data);
    } else {
      getApi = this.projectService.getList(data);
    }

    getApi.subscribe(
      response => {
        this.spinnerService.hide();
        this.gridView = {
          data: orderBy(response.items, this.sort),
          total: response.totalCount,
        };
        this.isNoRecords = response.items.length > 0 ? false : true;
        // if(response.items.length < this.pageSize) this.skip = 0;
      },
      err => {
        this.spinnerService.hide();
      },
    );
  }

  calculateGridProgressbar(totalTasks: number, totalCompletedTasks: number): number {
    if (totalTasks === 0 || isNaN(totalTasks) || isNaN(totalCompletedTasks)) {
      return 0;
    }
    return parseFloat(((totalCompletedTasks / totalTasks) * 100).toFixed(2));
  }

  onEdit(project: ProjectDto) {
    const dialogRef = this.dialogService.open({
      content: AddProjectComponent,
      width: 600,
      height: 600,
      cssClass: 'add-project-details',
    });
    dialogRef.content.instance.projectId = project.id;

    dialogRef.result.subscribe((res: any) => {
      if (res && res.confirmed) {
        this.getList();
      }
    });
  }

  onDelete(project: ProjectDto) {
    swal({
      title: NotificationTextMessage.areYouSureMessage,
      text: NotificationTextMessage.deleteMessageHeader + project.name + ' ?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then(confirmed => {
      if (confirmed) {
        this.projectService.delete(project.id).subscribe(
          res => {
            this.toasterService.success(
              NotificationMessage.deleteProjectSuccessMsg,
              '',
              this.commonService.toasterMessageConfiguration,
            );
            this.searchText = '';
            this.getList();
          },
          error => {
            const errorMessage = error.error.error.message || 'Delete Failed';
            this.toasterService.error(
              errorMessage,
              '',
              this.commonService.toasterMessageConfiguration,
            );
          },
        );
      }
    });
  }

  onProjectClick(projectData, event: MouseEvent): void {
    this.openInNewTab = event?.ctrlKey || event?.metaKey;

    if (this.openInNewTab) return;

    event?.preventDefault();

    if (!this.openInNewTab) {
      var params = {
        projectId: btoa(projectData.id),
        projectName: btoa(projectData.name),
      };

      this.router.navigate(['task-list'], { queryParams: params });
    }
  }

  getQueryParams(projectData) {
    return {
      projectId: btoa(projectData.id),
      projectName: btoa(projectData.name),
    };
  }

  onAddProject() {
    const dialogRef = this.dialogService.open({
      content: AddProjectComponent,
      width: 600,
      height: 600,
      cssClass: 'add-project-details',
    });
    if (this.isFromPortfolio) {
      dialogRef.content.instance.projectListType = ProjectListType.Portfolio;
      dialogRef.content.instance.portfolioId = this.portfolioId;
    } else if (this.isFromProduct) {
      dialogRef.content.instance.projectListType = ProjectListType.Product;
      dialogRef.content.instance.productId = this.productId;
    }
    dialogRef.result.subscribe((res: any) => {
      if (res && res.confirmed) {
        this.getList();
      }
    });
  }

  pageChange({ skip, take }: PageChangeEvent): void {
    this.skip = skip;
    this.pageSize = take;
    this.searchText = '';
    this.getList();
  }

  sortChange(sort): void {
    this.sort = sort;
    this.sorting = sort[0].field + ' ' + (sort[0].dir ?? '');
    this.getList();
  }
}
