<kendo-dialog-titlebar (click)="onCloseDialog()">
  <div>{{ logTimeTitle }}</div>
</kendo-dialog-titlebar>
<div>
  <form class="k-form k-form-md" [formGroup]="logTimeForm" validateOnSubmit>
    <div>
      <kendo-formfield class="mb-20 mt-0">
        <kendo-label text="Date" labelCssClass="k-form-label"></kendo-label>
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <kendo-datepicker
              #datePickerInput
              calendarType="classic"
              formControlName="date"
              [format]="'EEE, d MMM yyyy'"
              (valueChange)="updateDate($event)"
            ></kendo-datepicker>
          </div>
          <kendo-avatar
            *ngIf="!isAdmin"
            [initials]="commonService.getInitials(currentUser.userName)"
            [style.backgroundColor]="
              commonService.generateAvtarColor(currentUser.id, currentUser.userName)
            "
          ></kendo-avatar>
        </div>
      </kendo-formfield>
      <div *ngIf="isQuickAdd">
        <kendo-formfield class="mb-20 mt-0">
          <kendo-label text="Project" labelCssClass="k-form-label"></kendo-label>
          <kendo-dropdownlist
            [data]="projectList"
            [textField]="'name'"
            [valueField]="'id'"
            [valuePrimitive]="true"
            formControlName="projectId"
            (selectionChange)="onProjectChange($event.id)"
          >
            <ng-template kendoDropDownListItemTemplate let-dataItem>
              <span class="template">{{ dataItem.name }}</span>
            </ng-template>
          </kendo-dropdownlist>
          <kendo-formerror
            *ngIf="logTimeForm.get('projectId')?.touched && logTimeForm.get('projectId')?.invalid"
          >
            <span>Please select project</span>
          </kendo-formerror>
        </kendo-formfield>
      </div>
      <!-- <div *ngIf="isQuickAdd">
        <kendo-formfield class="mb-20 mt-0">
          <kendo-label text="Task List" labelCssClass="k-form-label"
            ><span class="required">*</span></kendo-label
          >
          <kendo-dropdownlist
            [data]="taskListsList"
            [textField]="'name'"
            [valueField]="'id'"
            [valuePrimitive]="true"
            formControlName="taskListId"
            (selectionChange)="onTaskListChange($event.id)"
          >
            <ng-template kendoDropDownListItemTemplate let-dataItem>
              <span class="template">{{ dataItem.name }}</span>
            </ng-template>
          </kendo-dropdownlist>
          <kendo-formerror
            *ngIf="logTimeForm.get('taskListId')?.touched && logTimeForm.get('taskListId')?.invalid"
          >
            <span class="error">Please select Task List</span>
          </kendo-formerror>
        </kendo-formfield>
      </div> -->
      <!-- <div *ngIf="isQuickAdd">
        <kendo-formfield class="mb-20 mt-0">
          <kendo-label text="Task" labelCssClass="k-form-label"
            ><span class="required">*</span></kendo-label
          >
          <kendo-dropdownlist
            [data]="taskList"
            [textField]="'name'"
            [valueField]="'id'"
            [valuePrimitive]="true"
            formControlName="taskId"
            (selectionChange)="onTaskChange($event.id)"
          >
            <ng-template kendoDropDownListItemTemplate let-dataItem>
              <span class="template">{{ dataItem.name }}</span>
            </ng-template>
          </kendo-dropdownlist>
          <kendo-formerror
            *ngIf="logTimeForm.get('taskId')?.touched && logTimeForm.get('taskId')?.invalid"
          >
            <span class="error">Please select Task</span>
          </kendo-formerror>
        </kendo-formfield>
      </div> -->
      <div *ngIf="isQuickAdd">
        <kendo-formfield class="mb-20 mt-0">
          <kendo-label [for]="tasks" text="Task" labelCssClass="k-form-label"
            ><span class="required">*</span></kendo-label
          >
          <kendo-combobox
            #tasks
            [data]="taskGroupedData"
            [textField]="'name'"
            [valueField]="'id'"
            [valuePrimitive]="true"
            formControlName="taskId"
            (selectionChange)="onTaskChange($event?.id)"
            [allowCustom]="false"
            [filterable]="true"
            (filterChange)="onFilterChange($event)"
          >
          </kendo-combobox>
          <kendo-formerror
            *ngIf="logTimeForm.get('taskId')?.touched && logTimeForm.get('taskId')?.invalid"
          >
            <span class="error">Please select Task</span>
          </kendo-formerror>
        </kendo-formfield>
      </div>
      <div class="d-flex gap-10" *ngIf="isAdmin">
        <kendo-formfield class="mb-20 mt-0">
          <kendo-label text="User" labelCssClass="k-form-label"></kendo-label>
          <kendo-dropdownlist
            [data]="userList"
            [textField]="'userName'"
            [valueField]="'userId'"
            [valuePrimitive]="true"
            formControlName="userId"
          >
            <ng-template kendoDropDownListItemTemplate let-dataItem>
              <span class="template">{{ dataItem.userName }}</span>
            </ng-template>
          </kendo-dropdownlist>
        </kendo-formfield>
        <kendo-formerror
          *ngIf="logTimeForm.get('userId')?.touched && logTimeForm.get('userId')?.invalid"
        >
          <span class="error">Please select user</span>
        </kendo-formerror>
      </div>
      <div>
        <div class="d-flex gap-10 mb-1">
          <div>
            <kendo-label text="Start Time">
              <kendo-timepicker
                [(ngModel)]="startTime"
                formControlName="startTime"
                (ngModelChange)="calculateTimeSpent()"
                [popupSettings]="{ popupClass: popupClass }"
              ></kendo-timepicker>
            </kendo-label>
          </div>
          <div>
            <kendo-label text="End Time">
              <kendo-timepicker
                [(ngModel)]="endTime"
                formControlName="endTime"
                (ngModelChange)="calculateTimeSpent()"
                [popupSettings]="{ popupClass: popupClass }"
              ></kendo-timepicker>
            </kendo-label>
          </div>
          <div>
            <kendo-label
              labelCssClass="k-form-label"
              [for]="timeSpent"
              text="Time Spent"
            ></kendo-label>
            <kendo-textbox
              [(ngModel)]="timeSpent"
              formControlName="timeSpent"
              required
              [readonly]="true"
              class="disabled"
            ></kendo-textbox>
          </div>
        </div>
        <div *ngIf="!startTime || !endTime">
          <span class="error">Both start time and end time are required</span>
        </div>
        <div
          *ngIf="
            logTimeForm.hasError('invalidTimeRange') &&
            (logTimeForm.get('startTime').dirty || logTimeForm.get('endTime').dirty)
          "
        >
          <span class="error">End time cannot be before the start time</span>
        </div>
        <div *ngIf="isProjectBillable">
          <kendo-label labelCssClass="k-form-label" text="Billable Hours"></kendo-label>
          <div class="d-flex gap-10">
            <div class="d-flex align-items-center flex-column">
              <kendo-numerictextbox
                class="w-80"
                formControlName="hours"
                [min]="0"
                [format]="'0'"
                [spinners]="false"
                placeholder="Hours"
              ></kendo-numerictextbox>
              <span class="text-gray">hours</span>
            </div>
            <div class="d-flex align-items-center flex-column">
              <kendo-numerictextbox
                class="w-80"
                formControlName="minutes"
                [min]="0"
                [format]="'0'"
                [spinners]="false"
                placeholder="Minutes"
                (blur)="onMinutesBlur()"
              ></kendo-numerictextbox>
              <span class="text-gray">minutes</span>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <a
              class="wmx-100 clamp header-tab"
              href="javascript:void(0)"
              (click)="quickAddTime('hours', 2)"
              >2h</a
            >
            <a
              class="wmx-100 clamp header-tab"
              href="javascript:void(0)"
              (click)="quickAddTime('hours', 1)"
              >1h</a
            >
            <a
              class="wmx-100 clamp header-tab"
              href="javascript:void(0)"
              (click)="quickAddTime('minutes', 45)"
              >45m</a
            >
            <a
              class="wmx-100 clamp header-tab"
              href="javascript:void(0)"
              (click)="quickAddTime('minutes', 30)"
              >30m</a
            >
            <a
              class="wmx-100 clamp header-tab"
              href="javascript:void(0)"
              (click)="quickAddTime('minutes', 15)"
              >15m</a
            >
          </div>
        </div>
      </div>
      <div class="mb-1">
        <div>
          <kendo-label text="Description" labelCssClass="k-form-label">
            <kendo-textarea
              placeholder="Description"
              [rows]="3"
              resizable="vertical"
              formControlName="description"
            ></kendo-textarea>
            <kendo-formerror *ngIf="logTimeForm.controls.description.errors?.maxlength"
              >Error: Description cannot be more than 5000 character!</kendo-formerror
            >
          </kendo-label>
        </div>
        <div class="mt-10">
          <input type="checkbox" #taskComplete kendoCheckBox formControlName="taskComplete" />
          <kendo-label
            class="k-checkbox-label"
            [for]="taskComplete"
            text="Mark as task Complete"
          ></kendo-label>
        </div>
      </div>
    </div>
  </form>
</div>
<kendo-dialog-actions>
  <button kendoButton fillMode="outline" (click)="onCloseDialog()">Close</button>
  <button
    kendoButton
    (click)="saveLogTime(logTimeForm.value, false)"
    themeColor="primary"
    class="k-button-solid-base"
  >
    {{ logTimeTitle }}
  </button>
  <button
    *ngIf="!isEdit"
    kendoButton
    (click)="saveLogTime(logTimeForm.value, true)"
    themeColor="primary"
    class="k-button-solid-base"
  >
    Log another time
  </button>
</kendo-dialog-actions>
