<kendo-popup *ngIf="showEstimatePopup" [offset]="position">
  <div class="k-menu-popup pt-0 pb-10 pl-10 pr-10">
    <p class="font-size-16 fw-500 text-primary mb-10">Estimate Time</p>
    <div>
      <form [formGroup]="estimateForm" validateOnSubmit class="k-form k-form-md">
        <div class="d-flex justify-content-between gap-15 flex-wrap mb-15">
          <div class="d-flex">
            <kendo-textbox
              formControlName="estimateHour"
              [clearButton]="false"
              size="small"
              maxlength="5"
              [style.width.px]="80"
              class="k-input-inner-text-box"
              aria-placeholder="hour"
              #listName
              data-field="estimateHour"
              (keydown)="commonService.onKeyDownForEstHour($event)"
              (paste)="onPaste($event)"
            ></kendo-textbox>
            <span class="m-auto ml-5">/h</span>
          </div>
          <div class="d-flex">
            <kendo-textbox
              formControlName="estimateMinute"
              [clearButton]="false"
              size="small"
              [style.width.px]="80"
              class="k-input-inner-text-box"
              aria-placeholder="minuts"
              #listName
              data-field="estimateMinute"
              (keydown)="commonService.onKeyDownForEstMinutes($event)"
              (paste)="onPaste($event)"
            ></kendo-textbox>
            <span class="m-auto ml-5">/m</span>
          </div>
        </div>
      </form>
    </div>
    <div>
      <button kendoButton (click)="onAddEstimate()">
        {{ buttonText }}
      </button>
    </div>
  </div>
</kendo-popup>
