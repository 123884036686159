import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import {
  SVGIcon,
  cancelIcon,
  chevronDownIcon,
  chevronRightIcon,
  filterAddExpressionIcon,
  pencilIcon,
  saveIcon,
  trashIcon,
} from '@progress/kendo-svg-icons';
import {
  AddEvent,
  CancelEvent,
  EditEvent,
  RemoveEvent,
  SaveEvent,
  TreeListComponent,
} from '@progress/kendo-angular-treelist';
import { DatePipe } from '@angular/common';
import { Priority } from 'tasks/task/config/src/enums/priority';
import { CommonService } from 'src/core/services';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProjectService } from 'projects/project-service/src/lib/proxy/project-service';
import { DashboardCardModel } from 'src/core/model/dashboard-card-model';
import { TaskModel } from 'src/core/model/dashboard-task-model';
import { ConfigStateService, ListService } from '@abp/ng.core';
import {
  CreateUpdateTaskDto,
  TaskStatusType,
  TaskType,
} from 'projects/task-service/src/lib/proxy/task-service';
import { TaskService } from 'projects/task-service/src/lib/proxy/task-service/task/task.service';
import { TaskListService } from 'projects/task-service/src/lib/proxy/task-service/task-list';
import { TaskDateDto } from 'tasks/task/src/pages';
import swal from 'sweetalert/dist/sweetalert.min.js';
import { NotificationTextMessage } from 'src/app/enum/notification';
import { ToasterService } from '@abp/ng.theme.shared';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { DialogService } from '@progress/kendo-angular-dialog';
import { DashboardCountPopupComponent } from './dashboard-count-popup/dashboard-count-popup.component';
import {
  DashboardService,
  TaskTypeStatus,
} from 'projects/project-service/src/lib/proxy/task-service/dashboard';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  //#region  expandedTreeList
  public taskData = [];
  public dashboradCardDetails: DashboardCardModel;
  public formGroup: FormGroup;
  public editedItem: TaskModel;
  public addExpressionIcon: SVGIcon = filterAddExpressionIcon;
  public trashIcon: SVGIcon = trashIcon;
  public saveIcon: SVGIcon = saveIcon;
  public cancelIcon: SVGIcon = cancelIcon;
  public pencilIcon: SVGIcon = pencilIcon;

  public arrowDown: SVGIcon = chevronRightIcon;
  public arrowUp: SVGIcon = chevronDownIcon;

  gridHeight: number;
  priorityFilter = Priority;
  taskStatusType = TaskStatusType;

  projectList: any[] = [];
  taskList: any[] = [];
  //#endregion

  isNoRecord = false;
  openInNewTab = false;

  taskTypeList: { id: TaskType; name: string; iconClass: string }[] = [];

  currentUser = '';
  taskTypeStatus = TaskTypeStatus;
  @ViewChild('taskListDropdown') taskListDropdown: DropDownListComponent;

  constructor(
    private toasterService: ToasterService,
    private dashboardService: DashboardService,
    private datePipe: DatePipe,
    public commonService: CommonService,
    private spinnerService: NgxSpinnerService,
    private projectService: ProjectService,
    public readonly list: ListService,
    private taskService: TaskService,
    private taskListService: TaskListService,
    private config: ConfigStateService,
    private dialogService: DialogService,
    private router: Router,
  ) {
    this.taskTypeList = this.commonService.getTaskTypeList();
    this.currentUser = this.config.getOne('currentUser').id;
    this.getProjectList();
  }

  ngOnInit(): void {
    this.getTaskList();
    this.getDashboardCardDetails();
  }

  onTaskCount(cardType: any, cardName: any, total: number): void {
    if (total === 0) {
      this.toasterService.info(
        'Sorry, No ' + cardName + ' available!',
        '',
        this.commonService.toasterMessageConfiguration,
      );
      return;
    }
    const dialogRef = this.dialogService.open({
      content: DashboardCountPopupComponent,
      width: 950,
      cssClass: 'task-count-popup',
    });
    const taskInfo = dialogRef.content.instance as DashboardCountPopupComponent;
    taskInfo.headerText = cardName;
    taskInfo.cardType = cardType;
    taskInfo.getTaskList();
  }

  getProjectList(): void {
    const ProjectStreamCreator = query => this.projectService.getList(query);
    this.list.hookToQuery(ProjectStreamCreator).subscribe(response => {
      this.projectList = response.items;
      this.getAllTaskListWithProjectId(this.projectList[0].id);
    });
  }

  getHeaderForDashboardList(headerName): any {
    switch (headerName) {
      case 'recentlyAssignedTask':
        return 'Recently Assigned Task/Bug';
      case 'dueTodayTask':
        return 'Due Today Task/Bug';
      default:
        return;
    }
  }

  getTaskList(): void {
    this.spinnerService.show();
    this.taskData = [];
    this.dashboardService.getDueTodayTaskList().subscribe(res => {
      this.taskData = [];
      for (let data in res) {
        if (res[data].length !== 0) {
          this.taskData.push({
            taskName: this.getHeaderForDashboardList(data),
            childData: res[data],
            hasChildren: res[data].length > 0 ? true : false,
            taskId: this.taskData.length,
            isHeader: true,
          });
        }
      }
      this.isNoRecord = this.taskData.length > 0 ? false : true;
      this.spinnerService.hide();
    });
  }

  getDashboardCardDetails() {
    this.dashboardService.getDashboardCard().subscribe(res => {
      this.dashboradCardDetails = {
        openTask: { cardName: res.card.cardName, total: res.card.total },
        closeTask: { cardName: res.card1.cardName, total: res.card1.total },
        openIssue: { cardName: res.card2.cardName, total: res.card2.total },
        closeIssue: { cardName: res.card3.cardName, total: res.card3.total },
      };
    });
  }

  fetchChildren = (item: any): any => {
    const subTaskList = item.childData.map(x => ({
      taskName: x.taskName,
      project: x.projectName,
      projectLogo: x.projectLogo,
      dueDate: this.datePipe.transform(x.duedate, 'MMM d, y'),
      priority: Priority[x.taskPriority],
      taskId: x.taskId + '/' + item.taskId,
      taskListId: x.taskListId,
      taskType: x.taskType,
      taskStatus: x.taskStatus,
      hasChildren: false,
      isAllSubTaskCompleted: x.isAllSubTaskCompleted,
      expandedIndex: item.id,
      taskListName: x.taskListName,
      isHeader: false,
      parentTaskId: x.parentTaskId,
      projectId: x.projectId,
    }));
    return subTaskList;
  };

  hasChildren = (item: TaskModel): boolean => {
    return item.hasChildren;
  };

  addHandler({ sender, parent }: AddEvent): void {
    this.closeEditor(sender);
    if (parent) {
      sender.expand(parent);
    }

    if (!parent) {
      this.formGroup = new FormGroup({
        taskId: new FormControl(null),
        taskName: new FormControl('', [Validators.required, Validators.maxLength(5000)]),
        taskType: new FormControl(this.taskTypeList[0].id),
        project: new FormControl(this.projectList[0].id, [Validators.required]),
        taskListId: new FormControl(this.taskList.length > 0 ? this.taskList[0].id : null, [
          Validators.required,
        ]),
        dueDate: new FormControl(''),
        priority: new FormControl(Priority.None),
      });

      // Add the new row to the header
      sender.addRow(this.formGroup, null);

      // Passing null as parent to add to the header
    } else {
      // Create form group for adding new row as child
      this.formGroup = new FormGroup({
        taskId: new FormControl(parent.id), // Set parent id for child row
        taskName: new FormControl('', [Validators.required, Validators.maxLength(5000)]),
        taskType: new FormControl(this.taskTypeList[0].id),
        project: new FormControl(this.projectList[0].id, [Validators.required]),
        taskListId: new FormControl(this.taskList[0].id, [Validators.required]),
        dueDate: new FormControl(''),
        priority: new FormControl(Priority.None),
      });

      // Add the new row as a child of the parent row
      sender.addRow(this.formGroup, parent);
    }
  }

  editHandler({ sender, dataItem }: EditEvent): void {
    this.closeEditor(sender, dataItem);
    const project = this.projectList.find(project => project.name === dataItem.project);
    this.getAllTaskListWithProjectId(project.id);
    this.formGroup = new FormGroup({
      id: new FormControl(dataItem.id),
      taskId: new FormControl(dataItem.taskId),
      taskType: new FormControl(dataItem.taskType),
      taskName: new FormControl(dataItem.taskName, [Validators.required]),
      project: new FormControl(project.id, [Validators.required]),
      taskListId: new FormControl(dataItem.taskListId, [Validators.required]),
      dueDate: new FormControl(dataItem.dueDate ? new Date(dataItem.dueDate) : ''),
      priority: new FormControl(dataItem.taskType),
      parentTaskId: new FormControl(dataItem.parentTaskId),
      taskStatus: new FormControl(dataItem.taskStatus),
    });
    this.editedItem = dataItem;
    sender.editRow(this.editedItem, this.formGroup);
  }

  closeEditor(treelist: TreeListComponent, dataItem: any = this.editedItem, isNew = false): void {
    treelist.closeRow(dataItem, isNew);
    this.editedItem = undefined;
    this.formGroup = undefined;
  }

  cancelHandler({ sender, dataItem, isNew }: CancelEvent): void {
    this.closeEditor(sender, dataItem, isNew);
  }

  saveHandler({ sender, dataItem, parent, formGroup, isNew }: SaveEvent): void {
    if (formGroup.controls.taskName.invalid) {
      formGroup.controls.taskName.markAsTouched();
      return;
    }

    if (formGroup.controls.project.invalid) {
      formGroup.controls.project.markAsTouched();
      return;
    }

    if (formGroup.controls.taskListId.invalid) {
      formGroup.controls.taskListId.markAsTouched();
      return;
    }

    this.spinnerService.show();
    const newTaskData: any = formGroup.value;
    let hasChildren: boolean = false;
    if (!isNew) {
      Object.assign(dataItem, newTaskData);
    } else if (parent) {
      parent.hasChildren = true;
      hasChildren = true;
    }

    const dueDate = new Date(newTaskData.dueDate);
    const param: CreateUpdateTaskDto = {
      isQuickEdit: true,
      id: isNew ? 0 : +newTaskData.taskId.split('/')[0],
      name: newTaskData.taskName,
      progress: 0,
      taskListId: newTaskData.taskListId,
      startdate: null,
      duedate:
        newTaskData.dueDate === ''
          ? null
          : new TaskDateDto(dueDate.getFullYear(), dueDate.getMonth() + 1, dueDate.getDate()),
      priority: +newTaskData.priority,
      estimateMinutes: 0,
      parentTaskId: isNew ? null : newTaskData.parentTaskId,
      notify: true,
      assignedToUserIds: [this.currentUser],
      taskType: newTaskData.taskType,
      taskStatus: isNew ? TaskStatusType.New : newTaskData.taskStatus,
      subTasks: [],
      description: '',
    };

    if (isNew) {
      this.taskService.create(param).subscribe(
        res => {
          this.spinnerService.hide();
          this.toasterService.success(
            NotificationTextMessage.taskAddedMessage,
            '',
            this.commonService.toasterMessageConfiguration,
          );
          this.taskData = [];
          this.getAllTaskListWithProjectId(this.projectList[0].id);
          this.refreshTaskListAndDashboard();
          sender.reload(parent);
        },
        err => {
          this.spinnerService.hide();
        },
      );
    } else {
      this.taskService.update(+newTaskData.taskId.split('/')[0], param).subscribe(
        res => {
          this.spinnerService.hide();
          this.toasterService.success(
            NotificationTextMessage.taskUpdatedMessage,
            '',
            this.commonService.toasterMessageConfiguration,
          );
          this.taskData = [];
          this.getAllTaskListWithProjectId(this.projectList[0].id);
          this.refreshTaskListAndDashboard();
          sender.reload(parent);
        },
        err => {
          this.spinnerService.hide();
        },
      );
    }

    sender.closeRow(dataItem, isNew);
  }

  removeHandler({ sender, dataItem, parent }: RemoveEvent): void {
    swal({
      title: NotificationTextMessage.areYouSureMessage,
      text: NotificationTextMessage.deleteMessageHeader + dataItem.taskName + ' ?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then(confirmed => {
      if (confirmed) {
        this.spinnerService.show();

        this.taskService.delete(+dataItem.taskId.split('/')[0]).subscribe(res => {
          this.toasterService.success(
            NotificationTextMessage.taskRemoveMessage,
            '',
            this.commonService.toasterMessageConfiguration,
          );
          this.spinnerService.hide();
          this.refreshTaskListAndDashboard();
          sender.reload(parent);
        });
      }
    });
  }

  onTaskCompleteChange(dataItem: any): void {
    const isTaskCompleted = dataItem.taskStatus === TaskStatusType.Completed;

    const action = isTaskCompleted
      ? this.taskService.markTaskAsReopenedById(dataItem.taskId.split('/')[0])
      : this.taskService.markTaskAsCompleteById(dataItem.taskId.split('/')[0]);

    action.subscribe(res => {
      this.refreshTaskListAndDashboard();
      this.toasterService.success(
        NotificationTextMessage.taskUpdatedMessage,
        '',
        this.commonService.toasterMessageConfiguration,
      );
    });
  }

  refreshTaskListAndDashboard(): void {
    // Refresh task list and dashboard
    this.getTaskList();
    this.getDashboardCardDetails();
  }

  getTaskTypeIconClass(data: number) {
    return data === 0
      ? 'far fa-clipboard-list font-size-14'
      : 'far fa-bug font-size-14 bug-icon-color';
  }

  getAllTaskListWithProjectId(projectId, isSelected = false, formGroup?: any): void {
    let newData = {
      projectId: projectId,
      showMilestones: true,
      getCompleteCount: true,
      getNewDefaultTask: true,
      maxResultCount: 1000,
      isFilterApply: null,
      userIds: null,
      startDate: null,
      endDate: null,
      taskStatusId: null,
      createdByMe: null,
      isLateTask: null,
      isDueWeek: null,
      taskStatusType: null,
      taskPriority: null,
    };
    this.spinnerService.show();
    this.taskListService.getList(newData).subscribe(res => {
      this.taskList = [];
      if (res.items.length > 0) {
        res.items.forEach(element => {
          const data = {
            id: element.id,
            taskName: element.name,
          };
          this.taskList.push(data);
        });
        if (isSelected) {
          this.formGroup.get('taskListId').setValue(this.taskList[0].id);
        }
      }
      this.spinnerService.hide();
    });
  }

  checkIsDisable(dataItem: any): boolean {
    return !dataItem.isAllSubTaskCompleted;
  }

  onTaskNameClick(data: any, event: MouseEvent): void {
    this.openInNewTab = event.ctrlKey || event.metaKey;

    if (this.openInNewTab) return;

    event.preventDefault();
    if (!this.openInNewTab) {
      var params = {
        taskId: btoa(data.taskId.split('/')[0]),
        projectId: btoa(data.projectId),
        projectName: btoa(data.project),
        taskListId: btoa(data.taskListId),
      };

      this.router.navigate(['task-Detail'], { queryParams: params });
    }
  }

  getQueryParams(data: any) {
    return {
      taskId: btoa(data.taskId.split('/')[0]),
      projectId: btoa(data.projectId),
      projectName: btoa(data.project),
      taskListId: btoa(data.taskListId),
    };
  }
}
