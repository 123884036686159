import { AuthService, ConfigStateService, PermissionService } from '@abp/ng.core';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { SeriesLabelsContentArgs } from '@progress/kendo-angular-charts';
import { ProgressBarOrientation } from '@progress/kendo-angular-progressbar';
import { OAuthService } from 'angular-oauth2-oidc';
import { HeaderDataEnum } from '../enum/header-data-enum';
import { DrawerMode } from '@progress/kendo-angular-layout';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { TimeLogPopupComponent } from 'tasks/task/src/pages/task/time-log-popup/time-log-popup.component';
import { DialogService } from '@progress/kendo-angular-dialog';
import { CommonService } from 'src/core/services';
import { RolesRights } from '../enum/rights';
import { TaskService } from 'projects/task-service/src/lib/proxy/task-service/task';
import { IdentityUserService } from '@abp/ng.identity/proxy';
import { QMenuComponent } from 'src/environments/environment';
import { ProjectService } from 'projects/project-service/src/lib/proxy/project-service';
import { QuickAdd } from '../enum/quick-add';
import { AddProjectComponent } from 'projects/project/src/pages';
import { AddTimelogList } from '../core/store/task.action';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit {
  get hasLoggedIn(): boolean {
    return this.oAuthService.hasValidAccessToken();
  }
  @ViewChild('qMenu') QMenu: QMenuComponent;
  public mode: DrawerMode = 'push';

  isMenuOverlay = false;
  isAdmin: boolean = false;

  public logoImage = 'assets/images/logo/logo.png';
  public iconlogoImage = 'assets/images/logo/logo-mob.png';
  public profileImage = 'assets/images/user.png';
  public downarrowImage = 'assets/images/arrow.svg';

  public menuList: Array<any> = [];

  public headerDataList: Array<{ text: string; value: number }> = [
    { text: HeaderDataEnum[HeaderDataEnum['Log out']], value: HeaderDataEnum['Log out'] },
  ];

  orientation: ProgressBarOrientation = 'horizontal';

  public labelContent(e: SeriesLabelsContentArgs): string {
    return e.category;
  }

  lineGraphData = [123, 276, 310, 212, 240, 156, 98];
  public pregressBarData = [
    { name: 'Asia', data: 50 },
    { name: 'India', data: 10 },
    { name: 'USA', data: 98 },
    { name: 'Germany', data: 60 },
  ];

  dashbordCount: any;
  isDashbordCount = false;
  isQPMS = false;

  //#region timer
  interval: any;
  currentTime: Date;
  elapsedTime: number;
  totalTimeTracked: string;
  timeSpent: string;
  isTimerPaused: boolean;
  endTime: Date;
  startTime: Date;
  isTimerRunning: boolean = false;
  timerInterval: any;
  projectId: any;
  taskId: any;
  taskName: any;
  projectName: any;
  createdBy: any;
  creatorId: any;
  //#endregion

  public wrapper: DOMRect;
  public windowRect: DOMRect;

  currentUser = '';

  constructor(
    private oAuthService: OAuthService,
    private permissionService: PermissionService,
    public authService: AuthService,
    private router: Router,
    private dialogService: DialogService,
    private commonService: CommonService,
    private taskService: TaskService,
    private projectService: ProjectService,
    protected service: IdentityUserService,
    private elRef: ElementRef,
    private cdr: ChangeDetectorRef,
    private config: ConfigStateService,
    private readonly store: Store,
  ) {
    this.currentUser = this.config.getOne('currentUser').name;
    var currentUserRole: any[] = this.config.getOne('currentUser').roles;
    if (currentUserRole.includes('admin')) {
      this.isAdmin = true;
    }
    this.setMenuList();
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.setHeaderText();
    });
    if (!this.authService.isAuthenticated) {
      this.router.navigate(['account']);
    }
  }

  setMenuList() {
    this.menuList = [
      {
        text: 'Quick Add',
        icon: 'fas fa-add',
        visible: true,
        id: 16,
        hasChildren: true,
      },
      {
        text: 'Log Time',
        icon: 'far fa-clock',
        action: QuickAdd.AddTimeLog,
        visible: true,
        id: 17,
        parentId: 16,
      },
      {
        text: 'Project',
        icon: 'far fa-folder',
        action: QuickAdd.AddProject,
        visible: this.isAdmin,
        id: 18,
        parentId: 16,
      },
      {
        text: 'Dashboard',
        icon: 'fas fa-home',
        path: '/dashboard',
        visible: true,
        headerText: 'Welcome to the Dashboard',
        id: 0,
        selected: true,
      },
      {
        text: 'Product',
        icon: 'far fa-clipboard-list',
        path: '/product',
        visible: this.isAdmin,
        headerText: 'Product',
        id: 1,
      },
      {
        text: 'Portfolio',
        icon: 'far fa-briefcase',
        path: '/portfolio',
        visible: this.isAdmin,
        headerText: 'Portfolio',
        id: 2,
      },
      {
        text: 'Projects',
        icon: 'far fa-square-kanban',
        path: '/projects',
        visible: true,
        headerText: 'All Projects',
        id: 3,
      },
      {
        text: 'Time',
        icon: 'fa-regular fa-clock',
        path: '/time',
        visible: true,
        headerText: 'Time',
        id: 4,
      },
      {
        text: 'Master',
        icon: 'far fa-square-kanban',
        visible: this.isAdmin,
        id: 5,
        hasChildren: true,
      },
      {
        text: 'Company',
        icon: 'far fa-user',
        path: '/company',
        visible: true,
        headerText: 'Company',
        id: 6,
        parentId: 5,
      },
      {
        text: 'Category',
        icon: 'far fa-user',
        path: '/category',
        visible: true,
        headerText: 'Category',
        id: 7,
        parentId: 5,
      },
      {
        text: 'Administration',
        icon: 'far fa-user-lock',
        visible:
          this.permissionService.getGrantedPolicy(RolesRights.AbpIdentity_Roles) ||
          this.permissionService.getGrantedPolicy(RolesRights.AbpIdentity_Users) ||
          this.permissionService.getGrantedPolicy(RolesRights.AbpTenantManagement_Tenants) ||
          this.permissionService.getGrantedPolicy(RolesRights.SettingManagement_Emailing),
        id: 8,
        hasChildren: true,
      },
      {
        text: 'Identity Management',
        icon: 'far fa-id-card',
        visible:
          this.permissionService.getGrantedPolicy(RolesRights.AbpIdentity_Roles) ||
          this.permissionService.getGrantedPolicy(RolesRights.AbpIdentity_Users),
        id: 9,
        parentId: 8,
        hasChildren: true,
      },
      {
        text: 'Tenant Management',
        icon: 'far fa-users-cog',
        visible: this.permissionService.getGrantedPolicy(RolesRights.AbpTenantManagement_Tenants),
        id: 10,
        parentId: 8,
        hasChildren: true,
      },
      {
        text: 'Settings',
        icon: 'far fa-cog',
        path: '/setting-management',
        visible: this.permissionService.getGrantedPolicy(RolesRights.SettingManagement_Emailing),
        headerText: 'Settings',
        id: 11,
        parentId: 8,
      },
      {
        text: 'Tenants',
        icon: 'far fa-user-friends',
        path: '/tenant-management/tenants',
        visible: this.permissionService.getGrantedPolicy(RolesRights.AbpTenantManagement_Tenants),
        headerText: 'Tenants',
        id: 12,
        parentId: 10,
      },
      {
        text: 'Roles',
        icon: 'far fa-clipboard-list',
        path: '/identity/roles',
        visible: this.permissionService.getGrantedPolicy(RolesRights.AbpIdentity_Roles),
        headerText: 'Roles',
        id: 13,
        parentId: 9,
      },
      {
        text: 'Users',
        icon: 'far fa-user',
        path: '/identity/users',
        visible: this.permissionService.getGrantedPolicy(RolesRights.AbpIdentity_Users),
        headerText: 'Users',
        id: 14,
        parentId: 9,
      },
    ];
    this.isQPMS = true;
  }

  ngAfterViewInit(): void {
    this.setHeaderText();
    this.isQPMS = true;
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.resetTimer();
    this.commonService.showTimer$.subscribe(res => {
      if (res && res.showTimer) {
        this.taskId = res.taskId;
        this.projectId = res.projectId;

        if (this.isTimerRunning) {
          this.onStopTimerClick();
          this.getTaskListDetails();
          this.getProjectListDetails();
        } else {
          this.onStartTimerClick();
          this.getTaskListDetails();
          this.getProjectListDetails();
        }
      }
    });

    this.setTimerPopupData();
    this.setMenuMode();
    window.addEventListener('resize', () => {
      this.setMenuMode();
    });
  }

  public restrictMovement() {
    this.cdr.detectChanges();

    this.wrapper = this.elRef.nativeElement.querySelector('.q-content-bg').getBoundingClientRect();
    const window = this.elRef.nativeElement.querySelector('.k-window');
    const windowRect = window.getBoundingClientRect();

    const minTop = this.wrapper.top;
    const minLeft = this.wrapper.left;
    const maxTop = this.wrapper.height;
    const maxLeft = this.wrapper.left + this.wrapper.width - windowRect.width;

    let newTop = windowRect.top;
    let newLeft = windowRect.left;

    if (windowRect.top < minTop) {
      newTop = minTop;
    }

    if (windowRect.left < minLeft) {
      newLeft = minLeft;
    }

    if (windowRect.top > maxTop) {
      newTop = maxTop;
    }

    if (windowRect.left > maxLeft) {
      newLeft = maxLeft;
    }

    window.style.top = newTop + 'px';
    window.style.left = newLeft + 'px';
  }

  getTaskListDetails(): void {
    this.taskService.get(this.taskId).subscribe(x => {
      this.taskName = x.name;

      this.createdBy = x.creatorName;
      this.creatorId = x.creatorId;
      localStorage.setItem('taskName', String(this.taskName));
    });
  }

  getProjectListDetails(): void {
    this.projectService.get(this.projectId).subscribe(x => {
      this.projectName = x.name;
      localStorage.setItem('projectName', String(this.projectName));
    });
  }

  setHeaderText(): void {
    const selectedItem = this.findSelectedItemByPath(this.menuList, this.router.url);

    if (selectedItem) {
      selectedItem.selected = true;
      this.QMenu.headerText = selectedItem.headerText;
      this.QMenu.tempHeaderText = selectedItem.headerText;
    }

    const currentUrl = this.router.url.split('?')[0];
    const queryParams = new URLSearchParams(this.router.url.split('?')[1]);

    if (currentUrl === '/task-list') {
      this.QMenu.headerText = 'Task List';
      this.QMenu.tempHeaderText = 'Task List';
    } else if (currentUrl === '/task-Detail') {
      this.QMenu.headerText = 'Task Detail';
      this.QMenu.tempHeaderText = 'Task Detail';
    } else if (currentUrl === '/project-list') {
      if (queryParams.has('productName')) {
        const productName = atob(decodeURIComponent(queryParams.get('productName')!));
        this.QMenu.headerText = `Product / ${productName}`;
        this.QMenu.tempHeaderText = `Product / ${productName}`;
      } else if (queryParams.has('portfolioName')) {
        const portfolioName = atob(decodeURIComponent(queryParams.get('portfolioName')!));
        this.QMenu.headerText = `Portfolio / ${portfolioName}`;
        this.QMenu.tempHeaderText = `Portfolio / ${portfolioName}`;
      }
    } else if (currentUrl === '/time') {
      this.QMenu.headerText = 'Time';
      this.QMenu.tempHeaderText = 'Time';
    } else if (currentUrl === '/project-dashboard') {
      this.QMenu.headerText = 'Project Dashboard';
      this.QMenu.tempHeaderText = 'Project Dashboard';
    }
  }

  setMenuMode(): void {
    this.isMenuOverlay = window.outerWidth < 1024;
    if (this.isMenuOverlay) {
      this.mode = 'overlay';
    } else {
      this.mode = 'push';
    }
  }

  findSelectedItemByPath(items: any[], path: string): any {
    items.forEach(item => (item.selected = false));
    for (const item of items) {
      if (item.path === path) {
        return item;
      }
      if (item.children) {
        const childItem = this.findSelectedItemByPath(item.children, path);
        if (childItem) {
          return childItem;
        }
      }
    }
    return null;
  }

  onHeaderClick(event) {
    switch (event.value) {
      case HeaderDataEnum.Setting:
        this.router.navigateByUrl('account/manage');
        break;
      case HeaderDataEnum['Log out']:
        this.authService.logout();
        break;
      default:
        break;
    }
  }

  login() {
    this.authService.navigateToLogin();
  }

  startTimer() {
    this.startTime = new Date();
    this.interval = setInterval(() => {
      this.currentTime = new Date();
      this.elapsedTime = this.currentTime.getTime() - this.startTime.getTime();
      this.formatTimeToString();
    }, 1000);
    localStorage.setItem('startTime', String(this.startTime));
    localStorage.removeItem('pausedTime');
    this.isTimerRunning = true;
  }

  stopTimer(): void {
    if (this.isTimerRunning) {
      clearInterval(this.timerInterval);
      this.isTimerRunning = false;
    }
  }

  onPauseTimerClick() {
    clearInterval(this.interval);
    this.isTimerPaused = true;
    localStorage.setItem('pausedTime', String(this.elapsedTime));
    this.setTimerPopupData();
  }

  onResumeTimerClick() {
    this.startTime = new Date(Date.now() - this.elapsedTime);
    this.interval = setInterval(() => {
      this.currentTime = new Date();
      this.elapsedTime = this.currentTime.getTime() - this.startTime.getTime();
      this.formatTimeToString();
    }, 1000);
    this.isTimerPaused = false;
    localStorage.setItem('startTime', String(this.startTime));
    localStorage.removeItem('pausedTime');
  }

  onStopTimerClick() {
    this.endTime = new Date();
    clearInterval(this.interval);
    this.isTimerRunning = false;
    localStorage.removeItem('startTime');
    localStorage.removeItem('pausedTime');
    localStorage.removeItem('projectName');
    localStorage.removeItem('pausedTime');
    this.openTimeLog();
  }

  formatTimeToString() {
    const seconds = Math.floor(this.elapsedTime / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    const formattedHours = this.formatTime(hours);
    const formattedMinutes = this.formatTime(minutes % 60);
    const formattedSeconds = this.formatTime(seconds % 60);

    this.totalTimeTracked = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    this.timeSpent = `${formattedHours}:${formattedMinutes}`;

    localStorage.setItem('time', this.totalTimeTracked);
  }

  formatTime(value: number): string {
    return value < 10 ? '0' + value : value.toString();
  }

  openTimeLog() {
    const dialogRef = this.dialogService.open({
      content: TimeLogPopupComponent,
      width: 500,
      cssClass: 'add-time-log-quickly',
    });
    document.body.style.overflow = 'hidden';

    this.startTime = this.startTime !== undefined ? this.startTime : new Date();
    this.endTime = this.endTime !== undefined ? this.endTime : new Date();
    dialogRef.content.instance.startTime = this.startTime;
    dialogRef.content.instance.endTime = this.endTime;
    dialogRef.content.instance.taskId = this.taskId;
    dialogRef.content.instance.projectId = this.projectId;
    dialogRef.content.instance.createdBy = this.createdBy;
    dialogRef.content.instance.creatorId = this.creatorId;
    dialogRef.content.instance.taskName = this.taskName;
    dialogRef.result.subscribe(res => {
      if (res) {
        this.commonService.getAllTimeLogList(this.taskId);
        document.body.style.overflow = 'scroll';
      }
    });
  }

  resetTimer() {
    this.elapsedTime = 0;
    this.interval = null;
    this.isTimerRunning = false;
    this.isTimerPaused = false;
    this.totalTimeTracked = '00:00:00';
    this.timeSpent = '00:00';
  }

  setTimerPopupData(): void {
    const storedStartTime = localStorage.getItem('startTime');
    const storedPausedTime = localStorage.getItem('pausedTime');
    this.projectName = localStorage.getItem('projectName');
    this.taskName = localStorage.getItem('taskName');

    if (storedPausedTime) {
      this.elapsedTime = +storedPausedTime;
      this.isTimerRunning = true;
      this.isTimerRunning = true;
      this.isTimerPaused = true;
    }

    if (storedStartTime && !this.isTimerPaused) {
      this.isTimerRunning = true;
      this.startTime = new Date(storedStartTime);
      this.isTimerRunning = true;
      this.interval = setInterval(() => {
        this.currentTime = new Date();
        this.elapsedTime = this.currentTime.getTime() - this.startTime.getTime();
        this.formatTimeToString();
      }, 1000);
    }
  }

  calculateWindowTop(): number {
    return window.innerHeight - 150;
  }

  calculateWindowLeft(): number {
    return window.innerWidth - 500;
  }

  onStartTimerClick() {
    this.startTime = new Date();
    this.interval = setInterval(() => {
      this.currentTime = new Date();
      this.elapsedTime = this.currentTime.getTime() - this.startTime.getTime();
      this.formatTimeToString();
    }, 1000);
    localStorage.setItem('startTime', String(this.startTime));
    localStorage.removeItem('pausedTime');
    this.isTimerRunning = true;
  }

  closeTimerPopup() {
    this.isTimerRunning = false;
    this.endTime = new Date();
    clearInterval(this.interval);
    localStorage.removeItem('startTime');
    localStorage.removeItem('pausedTime');
    localStorage.removeItem('projectName');
    localStorage.removeItem('pausedTime');
  }

  onActionClick(event) {
    switch (event) {
      case QuickAdd.AddTimeLog:
        const dialogRef = this.dialogService.open({
          content: TimeLogPopupComponent,
          width: 500,
          height: 600,
          cssClass: 'add-time-log-quickly',
        });

        dialogRef.content.instance.isQuickAdd = true;
        dialogRef.result.subscribe((res: any) => {
          if (res && res.confirmed) {
            this.store.dispatch(new AddTimelogList(res.data)).subscribe();
          }
        });
        break;
      case QuickAdd.AddProject:
        this.dialogService.open({
          content: AddProjectComponent,
          width: 600,
          height: 600,
          cssClass: 'add-project-details',
        });
        break;
      default:
        break;
    }
  }
}
