import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-set-status-popup',
  templateUrl: './set-status-popup.component.html',
  styleUrls: ['./set-status-popup.component.scss'],
})
export class SetStatusPopupComponent {
  @Output() statusSelected = new EventEmitter<string>();

  @Input() isVisible: boolean = false;
  @Input() position: { top: number; left: number } | null = null;
  @Input() taskStatusTypeList: any;
  @Input() taskData: any;

  constructor() {}

  selectStatus(status: any): void {
    this.statusSelected.emit(status);
  }
}
