import { RestService, Rest } from '@abp/ng.core';
import type { PagedAndSortedResultRequestDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { LeftAndOverTimeTaskDto } from '../dtos/models';
import type { CreateUpdateTaskDto, GetTaskInputDto, TaskDto } from '../models';
import type { UpdateTaskParentTaskIdDto } from '../../service/project/task/dtos/models';

@Injectable({
  providedIn: 'root',
})
export class TaskService {
  apiName = 'TaskService';

  create = (input: CreateUpdateTaskDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, TaskDto>(
      {
        method: 'POST',
        url: '/api/task',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  delete = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/task/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  get = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, TaskDto>(
      {
        method: 'GET',
        url: `/api/task/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  getLeftAndOverTimeTasksList = (projectId: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, LeftAndOverTimeTaskDto>(
      {
        method: 'GET',
        url: '/api/task/LeftAndOverTimeTasks',
        params: { projectId },
      },
      { apiName: this.apiName, ...config },
    );

  getList = (input: GetTaskInputDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<TaskDto>>(
      {
        method: 'GET',
        url: '/api/task',
        params: {
          projectId: input.projectId,
          taskListId: input.taskListId,
          getSubTasks: input.getSubTasks,
          includeLoggedTime: input.includeLoggedTime,
          completedOnly: input.completedOnly,
          userIds: input.userIds,
          startDate: input.startDate,
          dueDate: input.dueDate,
          taskStatusId: input.taskStatusId,
          createdByMe: input.createdByMe,
          isLateTask: input.isLateTask,
          isDueWeek: input.isDueWeek,
          searchTerm: input.searchTerm,
          taskStatusType: input.taskStatusType,
          taskPriority: input.taskPriority,
          taskView: input.taskView,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getSubTasksByParentTaskIdAndPagedAndSortedResultRequest = (
    parentTaskId: number,
    pagedAndSortedResultRequest: PagedAndSortedResultRequestDto,
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, PagedResultDto<TaskDto>>(
      {
        method: 'POST',
        url: `/api/task/${parentTaskId}/subtasks`,
        body: pagedAndSortedResultRequest,
      },
      { apiName: this.apiName, ...config },
    );

  markTaskAsCompleteById = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>(
      {
        method: 'POST',
        url: `/api/task/${id}/markascomplete`,
      },
      { apiName: this.apiName, ...config },
    );

  markTaskAsReopenedById = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>(
      {
        method: 'POST',
        url: `/api/task/${id}/markasreopen`,
      },
      { apiName: this.apiName, ...config },
    );

  update = (id: number, input: CreateUpdateTaskDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, TaskDto>(
      {
        method: 'PUT',
        url: `/api/task/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  updateTaskParentTaskId = (
    updateTaskParentTaskId: UpdateTaskParentTaskIdDto,
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, boolean>(
      {
        method: 'PUT',
        url: '/api/task/UpdateTaskParentTask',
        body: updateTaskParentTaskId,
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
