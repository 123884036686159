import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TimeLogPopupComponent } from '../../time-log-popup/time-log-popup.component';
import { DialogService } from '@progress/kendo-angular-dialog';
import { CommonService } from 'src/core/services';
import { ProjectService } from 'projects/project-service/src/lib/proxy/project-service';
import { AddTimelogList } from 'src/app/core/store/task.action';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-log-time-panel',
  templateUrl: './log-time-panel.component.html',
  styleUrls: ['./log-time-panel.component.scss'],
})
export class LogTimePanelComponent {
  isProjectBillable = false;
  startTime: Date;
  endTime: Date;
  @Output() triggerViewTimelog = new EventEmitter();
  @Output() triggerTimelogAdded = new EventEmitter();

  @Input() isVisible: boolean = false;
  @Input() anchor!: HTMLElement | null;
  @Input() projectId: any;
  @Input() taskData: any = null;

  constructor(
    private dialogService: DialogService,
    private commonService: CommonService,
    private projectService: ProjectService,
    private store: Store,
  ) {}

  getProjectDetail(): void {
    this.projectService.get(this.projectId).subscribe((res: any) => {
      this.isProjectBillable = res.isBillable;
      this.openTimeLog();
    });
  }

  onViewTimelogClick(): void {
    this.triggerViewTimelog.emit();
  }

  onStartTimerClick() {
    const timerData = {
      showTimer: true,
      taskId: this.taskData.id,
      projectId: this.projectId,
    };

    this.commonService.showTimerPopup(timerData);
  }

  onLogMoreTimeClick(): void {
    this.getProjectDetail();
  }

  openTimeLog() {
    const dialogRef = this.dialogService.open({
      content: TimeLogPopupComponent,
      width: 500,
      cssClass: 'add-time-log-quickly',
    });
    this.startTime = new Date();
    this.endTime = new Date();
    dialogRef.content.instance.startTime = this.startTime;
    dialogRef.content.instance.endTime = this.endTime;
    dialogRef.content.instance.taskId = this.taskData.id;
    dialogRef.content.instance.taskName = this.taskData.taskName;
    dialogRef.content.instance.projectId = this.projectId;
    dialogRef.content.instance.isProjectBillable = this.isProjectBillable;

    dialogRef.result.subscribe((res: any) => {
      if (res) {
        if (res.confirmed) {
          this.triggerTimelogAdded.emit(res.data);
        }
        document.body.style.overflow = 'scroll';
      }
    });
  }
}
