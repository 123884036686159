<kendo-tabstrip class="setting-page-tab d-flex align-items-center">
  <kendo-tabstrip-tab title="Status" [selected]="true">
    <ng-template kendoTabContent>
      <div class="grid-wrapper">
        <kendo-grid
          #grid
          [data]="gridData"
          [pageable]="true"
          [skip]="state.skip"
          [pageSize]="state.take"
          [rowClass]="rowCallback"
          (dataStateChange)="dataStateChange($event)"
          (edit)="editHandler($event)"
          (cancel)="cancelHandler($event)"
          (save)="saveHandler($event)"
          (remove)="removeHandler($event)"
          (add)="addHandler($event)"
          (pageChange)="pageChange($event)"
          class="setting-grid"
          [height]="gridHeight"
        >
          <ng-template kendoGridNoRecordsTemplate>
            <div *ngIf="isNoRecords" class="no-records-text-container">No Records Available</div>
          </ng-template>
          <ng-template kendoGridToolbarTemplate>
            <div>
              <button kendoGridAddCommand><i class="far fa-plus pr-5"></i>Add New Status</button>
            </div>
          </ng-template>
          <kendo-grid-column [width]="20" *ngIf="gridData.total > 0 || isAddHandle">
            <ng-template kendoGridCellTemplate>
              <span class="k-icon k-font-icon k-i-reorder hand-cursor"></span>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column
            *ngIf="gridData.total > 0 || isAddHandle"
            [hidden]="true"
            field="id"
            title="id"
          ></kendo-grid-column>
          <kendo-grid-column
            *ngIf="gridData.total > 0 || isAddHandle"
            field="columnname"
            title="Status"
            [width]="100"
          >
            <ng-template kendoGridHeaderTemplate> Status </ng-template>

            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="d-flex gap-10 align-items-center">
                {{ dataItem.columnname }}
              </div>
            </ng-template>

            <ng-template
              kendoGridEditTemplate
              let-dataItem="dataItem"
              let-formGroup="formGroup"
              let-rowIndex="rowIndex"
              let-isNew="isNew"
            >
              <form class="k-form k-form-md" [formGroup]="formGroup">
                <fieldset class="k-form-fieldset d-flex gap-5">
                  <kendo-textbox
                    [formControl]="formGroup.get('columnname')"
                    (keydown.enter)="
                      saveHandler({
                        sender: grid,
                        rowIndex: rowIndex,
                        formGroup: formGroup,
                        isNew: isNew,
                      })
                    "
                    class="k-input-inner-text-box"
                    placeholder="Enter status"
                  ></kendo-textbox>
                </fieldset>
              </form>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-command-column
            *ngIf="gridData.total > 0 || isAddHandle"
            title=""
            [width]="220"
          >
            <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem="dataItem">
              <ul class="grid-action-list grid-command-action">
                <div class="d-flex gap-5 justify-content-end">
                  <li>
                    <a class="grid-command-action-links" kendoGridEditCommand title="Edit"
                      ><i class="far fa-pen"></i
                    ></a>
                  </li>
                  <li>
                    <a class="grid-command-action-links" kendoGridRemoveCommand title="Delete"
                      ><i class="far fa-trash-alt"></i
                    ></a>
                  </li>
                  <li [ngClass]="{ 'curser-not-allowd': formGroup?.invalid }">
                    <a
                      class="grid-command-action-links"
                      kendoGridSaveCommand
                      [disabled]="formGroup?.invalid"
                    >
                      <ng-container *ngIf="isNew; else editMode">
                        <i class="far fa-floppy-disk"></i>
                      </ng-container>
                      <ng-template #editMode>
                        <i class="far fa-pen"></i>
                      </ng-template>
                    </a>
                  </li>
                  <li>
                    <a class="grid-command-action-links" kendoGridCancelCommand>
                      <ng-container *ngIf="isNew; else cancelMode">
                        <i class="far fa-ban" title="Cancel"></i>
                      </ng-container>
                      <ng-template #cancelMode>
                        <i class="far fa-ban" title="Cancel"></i>
                      </ng-template>
                    </a>
                  </li>
                </div>
              </ul>
            </ng-template>
          </kendo-grid-command-column>
        </kendo-grid>
      </div>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>
