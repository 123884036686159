import { Component } from '@angular/core';
import { DialogService } from '@progress/kendo-angular-dialog';
import { PagerType, PagerPosition, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { StatusTypeEnum } from 'src/app/enum/status-type-enum';
import { CommonService } from 'src/core/services';
import { AddUserComponent } from '../add-user/add-user.component';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent {
  searchText = '';
  gridHeight: number;
  public selectedFilterValue = 1;
  public statusFilter: Array<{ text: string; value: number }> = [{ text: 'All Status', value: 1 }];
  statusType = StatusTypeEnum;
  //#region  Pagination
  public type: PagerType = 'numeric';
  public buttonCount = 5;
  public info = true;
  public pageSizes = true;
  public previousNext = true;
  public pagerposition: PagerPosition = 'bottom';
  public pageSize = 5;
  public skip = 0;
  public sorting = null;
  public multiple = false;
  public allowUnsort = true;
  //#endregion
  usersData = [
    {
      People: 'ALFKI',
      LastVisited: new Date(),
      CreatedBy: 'Maria Anders',
      Status: 1,
      avatar: 'https://demos.telerik.com/kendo-ui/content/web/Customers/RICSU.jpg',
    },
    {
      People: 'ANATR',
      LastVisited: new Date(),
      CreatedBy: 'Ana Trujillo',
      Status: 1,
      avatar: 'https://demos.telerik.com/kendo-ui/content/web/Customers/RICSU.jpg',
    },
    {
      People: 'ANTON',
      LastVisited: new Date(),
      CreatedBy: 'Antonio Moreno',
      Status: 1,
      avatar: 'https://demos.telerik.com/kendo-ui/content/web/Customers/RICSU.jpg',
    },
  ];

  public sort: SortDescriptor[] = [
    {
      field: '',
      dir: 'asc',
    },
  ];

  constructor(
    private dialogService: DialogService,
    public commonService: CommonService,
  ) {}

  ngOnInit(): void {
    this.calculateGridHeight();

    window.addEventListener('resize', () => {
      this.calculateGridHeight();
    });
  }

  calculateGridHeight(): void {
    const screenHeight = window.innerHeight;
    const headerHeight = 64;
    const gridHeaderHeight = 69;
    const wrapperpadding = 60;

    this.gridHeight = screenHeight - (headerHeight + gridHeaderHeight + wrapperpadding);
  }

  selectionChange(event): void {}

  onUserAddEdit(id?: any): void {
    const dialogRef = this.dialogService.open({
      content: AddUserComponent,
    });
    const attachFileInfo = dialogRef.content.instance as AddUserComponent;
    attachFileInfo.peopleId = id;
    attachFileInfo.setOnLoadData();
    dialogRef.result.subscribe(res => {
      if (res) {
        this.usersData;
      }
    });
  }

  onEdit(data: any): void {}

  onDelete(data: any): void {}

  public pageChange({ skip, take }: PageChangeEvent): void {
    this.skip = skip;
    this.pageSize = take;
    this.searchText = '';
  }

  sortChange(sort): void {
    this.sorting = sort[0].field;
  }

  onSearch(event: any): void {}
}
